import { ListItems } from "../../comp/ListItems"
import { EmptyView } from "../../comp/Loading"
import { BottomLogoView } from "../../comp/LogoView"
import { Modal } from "../../comp/Modal"
import { CreateTemplateField } from "../../comp/forms/CreateTemplateField"
import TemplateFieldRow from "../../comp/rows/TemplateFieldRow"
import { useDragReorder } from "../../hooks/useDrag"
import { useModal } from "../../hooks/useModal"
import { ITemplate, ITemplateField } from "../../model/models"
import strings from "../../strings"

interface TemplateFieldsViewProps {
    template?: ITemplate
    // onAddField?: () => void 
    saveFields?: (nextFields: ITemplateField[]) => void
}

export default function TemplateFieldsView({ template, saveFields }: TemplateFieldsViewProps) {
    const templateFieldModal = useModal<ITemplateField>()
    const dragging = useDragReorder<ITemplateField>(template?.fields || [],
        (nextFields) => {
            if (!saveFields) {
                return
            }
            nextFields.forEach((m, index) => m.index = index)
            saveFields(nextFields)
        })

    async function saveTemplateFieldHandler(field: ITemplateField) {
        templateFieldModal.closeModal()
        if (saveFields) {
            // console.log("Save field: ", field)
            let nextFields = template?.fields?.filter(fld => fld.name !== field.name) || []
            nextFields.push(field)
            saveFields(nextFields)
            // saveTemplateHandler(nextFields)
        }
    }
    function deleteTemplateFieldHandler(field: ITemplateField) {
        if (!saveFields || !template) {
            return
        }
        if (!global.confirm(strings.confirm_delete_template_field)) {
            return
        }
        const copyListItems = template.fields.filter(fld => fld.name !== field.name)
        saveFields(copyListItems)
    }

    return (
        <>
            {saveFields &&
                <div className="flex flex-row justify-start">
                    <button className="textbutton" onClick={
                        () => templateFieldModal.openModal({} as ITemplateField)
                    }>{strings.button_add_template_field}</button>
                </div>
            }
            {(template && template.fields.length > 0)
                ? <ListItems items={template.fields}
                    render={fld =>
                        <TemplateFieldRow key={fld.name}
                            field={fld}
                            onDrag={dragging}
                            onClick={() => saveFields && templateFieldModal.openModal(fld)}
                            onDelete={() => deleteTemplateFieldHandler(fld)} />
                    }
                />
                : <EmptyView />
            }
            <BottomLogoView />
            {templateFieldModal.modal &&
                <Modal title={strings.dlg_title_edit_template_field}
                    onClose={templateFieldModal.closeModal} >
                    <CreateTemplateField
                        item={templateFieldModal.modal}
                        onSave={saveTemplateFieldHandler}
                        onCancel={templateFieldModal.closeModal}
                    />
                </Modal>
            }
        </>
    )
}