import { SubmitHandler, useForm } from 'react-hook-form';
import { IContest } from '../../model/models';
import DataService from '../../services/DataService';
import strings from '../../strings';
import { LabelWith } from '../Label';
import { descrValidation, titleValidation } from './validations';
import { AxiosError } from 'axios';
import { ErrorMessage } from '../ErrorMessage';

interface CreateContestProps {
    contest: IContest
    onSave: (contest: IContest) => void
    onCancel?: () => void
}

export function CreateContest({ contest, onSave, onCancel }: CreateContestProps) {
    const { register, formState: { errors }, handleSubmit, setError } = useForm<IContest>({ defaultValues: contest })

    const onSubmit: SubmitHandler<IContest> = async (data) => {
        try {
            const resp = await DataService.saveContest(contest.id, data)
            onSave(resp.data);    
        } catch(e) {
            console.error(e)
            const err = e as AxiosError
            const data: any = err.response?.data
            setError("root", {
                type: "manual",
                message: data?.message || err.message
            })
        }
    }

    return (
        <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
            {errors.root?.message &&
            <ErrorMessage message={errors.root?.message}/>
            }
            <LabelWith text={strings.lab_title}
                error={errors.title}>
                <input className='textinput' 
                    placeholder={strings.lab_title}
                    {...register("title", titleValidation())} />
            </LabelWith>
            
            <LabelWith text={strings.lab_descr}
                error={errors.description}>
                <textarea className='textinput' 
                    placeholder={strings.lab_descr}
                    rows={5}
                    {...register("description", descrValidation())} />
            </LabelWith>

            <div className='flex flex-row justify-end gap-2 pt-4'> 
                <button type='submit' 
                    className='greenbutton'>{strings.button_save}</button>
                {onCancel &&
                <button type='reset' 
                    className='textbutton' 
                    onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </form>
    )
}