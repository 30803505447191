import ExcelJS, { Column } from "exceljs"
import { IPoint, ImageAlign, VerticalAlignField } from "../model/common"
import { extractImageSrc } from "../comp/ImageFB"
import { PhotoSize } from "../model/models"
import { saveAs } from "file-saver"
import { IDocRow, IXlsxColumnWidths } from "../model/xlsx"
import { MAX_SPAN, indexToAbc } from "../comp/docs/models"
import { DEF_COLUMN_WIDTH, DEF_ROW_HEIGHT } from "../pages/EditPresentationPage/DocTable"
import { coverUri } from "../api/crop"

function pxToUnit(px: number) {
    return (px / 7.0017)
}

function toXlsxColor(col?: string) {
    return col ? { argb: 'FF' + col.replace("#", "").toUpperCase() } : undefined
}

function toXlsxVerticalAlign(align?: VerticalAlignField): 'top' | 'middle' | 'bottom' | 'distributed' | 'justify' {
    switch (align) {
        case VerticalAlignField.start:
            return "top"
        case VerticalAlignField.center:
            return "middle"
        case VerticalAlignField.end:
            return "bottom"
        default:
            return "top"
    }
}

async function addLoadImage(
    workbook: ExcelJS.Workbook, ws: ExcelJS.Worksheet,
    imageUri: string, row: number, col: number, width: number, height: number,
    align: ImageAlign | undefined, pos: IPoint | undefined
) {
    try {
        let resp = await coverUri(extractImageSrc(imageUri, PhotoSize.medium), width, height, {
            align, pos,
            resultFormat: "image/png"
        })
        let imgIndx = workbook.addImage({
            base64: resp,
            // buffer: buff,
            extension: "png"//resp.extension
        })
        let cellFmt = `${indexToAbc(col)}${row + 1}`
        ws.addImage(imgIndx, `${cellFmt}:${cellFmt}`)
    } catch (e) {
        console.error(e)
    }
}

export async function buildXlsx(name: string, rows: IDocRow[], maxColumn: number, cols?: IXlsxColumnWidths) {
    const workbook = new ExcelJS.Workbook()
    const ws = workbook.addWorksheet()
    // console.log(`Build xlsx ${maxColumn}`)
    if (cols) {
        ws.columns = Array.from({ length: maxColumn }, (m, n) => {
            let widthPx = cols[n] || DEF_COLUMN_WIDTH
            let width = pxToUnit(widthPx)
            // console.log(`Col ${n} width: ${widthPx} ${width}`)
            return { width } as Column
        })
    }

    // rows.forEach( row => {
    for (let row of rows) {
        // let texts = row.columns.map( m => m.text?.text || "")
        // console.log(`Add row ${row.index}/${ws.rowCount}`, JSON.stringify(texts))
        // let rw = ws.addRow([])//
        let rw = ws.getRow(row.index + 1)
        // console.log(row)
        for (let m of row.columns) {
            let cellFtm = indexToAbc(m.col) + rw.number
            if (m.span) {// && m.span > 1
                let useSpan = m.span === MAX_SPAN ? maxColumn - m.col - 1 : m.span
                ws.mergeCells(`${cellFtm}:${indexToAbc(m.col + useSpan)}${rw.number}`)
            }
            let cell = rw.getCell(m.col + 1)
            if (m.image) {
                let width = (cols && cols[m.col]) || DEF_COLUMN_WIDTH
                let height = row.height || DEF_ROW_HEIGHT
                await addLoadImage(workbook, ws, m.image.src, row.index, m.col, width, height, m.image.align, m.image.pos)
            } else if (m.text) {
                cell.value = m.text.text
                // cell.
                if (m.text.style) {
                    if (m.text.style.background)
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: toXlsxColor(m.text.style.background),
                            // bgColor: toXlsxColor(m.text.style.background),
                        };
                    cell.font = {
                        size: m.text.style.fontSize,
                        // family: m.text.style.fontFamily,
                        bold: m.text.style.bold,
                        italic: m.text.style.italic,
                        underline: m.text.style.underline,
                        strike: m.text.style.strike,
                        color: toXlsxColor(m.text.style.color),
                    }
                }
                cell.alignment = {
                    wrapText: true,
                    horizontal: m.text.align,
                    vertical: toXlsxVerticalAlign(m.text.verticalAlign)
                }
            }
        }

        if (row.height)
            rw.height = row.height
    }

    const buf = await workbook.xlsx.writeBuffer()
    saveAs(new Blob([buf]), `${name}.xlsx`)
}
