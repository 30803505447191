import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BackLink } from "../comp/BackLink";
import { ErrorMessage } from "../comp/ErrorMessage";
import { Loading } from "../comp/Loading";
import { useTocData, useVoteResult } from "../hooks/useTocData";
import { dbid } from "../model/models";
import { IPresentation } from "../model/pptx";
import paths from "../paths";
import DocService from "../services/DocService";
import strings from "../strings";
import { PresentationEditor } from "./EditPresentationPage/PresentationEditor";

function usePresentation(presentationId: dbid) {
    const [ loading, setLoading ] = useState<boolean>()
    const [ pptx, setPptx ] = useState<IPresentation>()
    const toc = useTocData(pptx?.contestId || "")
    const voteData = useVoteResult(pptx?.contestId || "", pptx?.voteStageId || "")

    async function load() {
        setLoading(true)
        try {
            const resp = await DocService.getPresentation(presentationId)
            setPptx(resp.data)
        } catch(e) {
            console.error(e)
        } finally {
            // console.log("Loading toc finish")
            setLoading(false)
        }
    }
    
    useEffect(() => {
        if (pptx?.id !== presentationId)
            load()
    }, [presentationId])
    return { pptx, setPptx, toc: toc.toc, voteData: voteData.data, loading: loading || toc.loading || voteData.loading }
}

export function EditPresentationPage2() {
    const navigate = useNavigate()
    const presentationId = useParams().id || ""
    const { pptx, setPptx, voteData, loading, toc } = usePresentation(presentationId)

    function deletePresentationHandler(_: dbid, contestId: dbid) {
        navigate(paths.contest_by_id(contestId, 2))
    }
    function changePresentationHandler(next: IPresentation) {
        setPptx(next)
    }
    if (loading) {
        return (
            <Loading/>
        )
    } else if (pptx && toc) {
        return (
            <div className="flex flex-col">
                <div className="maincontainer">
                    <BackLink to={paths.contest_by_id(pptx.contestId, 2)}
                        text={strings.lab_documents}
                        current={`${strings.lab_presentation} - ${pptx.name}`}/>
                </div>                
                <PresentationEditor 
                    presentation={pptx} 
                    toc={toc} 
                    voteData={voteData}
                    onDeleted={deletePresentationHandler}
                    onChanged={changePresentationHandler}
                    />
            </div>
        )
    } else {
        return (
            <ErrorMessage message={strings.err_load_presentation} />
        )
    }
}