import { applyMiddleware, combineReducers, createStore } from "redux"
// import { configureStore } from '@reduxjs/toolkit'
import thunk from "redux-thunk"
import { authReducer } from './reducers/authReducer'

const rootReducer = combineReducers({
    auth: authReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk))
// export const store = configureStore({
//     reducer: {
//       auth: authReducer,
//     //   comments: commentsReducer,
//     //   users: usersReducer
//     }
// })

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch