import menus from "../../menus"
import { INomination } from "../../model/models"
import { ContextMenuDir, IContextMenuItem } from "../ContextMenu"
import ContextMenuButton from "../ContextMenuButton"
import { DescrView } from "../DescrView"

interface NominationRowProps {
    className?: string
    item: INomination
    onClick?: () => void
    onDelete?: () => void
}

const nominationMenu = [menus.delete]

export default function NominationRow(props: NominationRowProps) {
    // const navigate = useNavigate()
    function menuHandler(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.DELETE: 
                props.onDelete && props.onDelete()
                break;
        }
    }
    return (
        <div className={ 'itemrow w-full flex flex-row justify-between ' + (props.className || "") + (props.onClick ? "" : " disabled") } onClick={props.onClick}>
            <div>
                <span className="text-lg">{props.item.title}</span>
                {props.item.description && 
                <DescrView className="text-sm line-clamp-3" body={props.item.description}/>
                }
            </div>
            <ContextMenuButton 
                menu={nominationMenu} dir={ContextMenuDir.FromRightTop} 
                onClick={menuHandler}
                onVisible={mi => mi.id === menus.DELETE && !!props.onDelete}
                />
        </div>
    )
}