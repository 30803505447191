import { IContest, PhotoSize } from "../../model/models";
import { useNavigate } from 'react-router-dom';
import ContextMenuButton from "../ContextMenuButton";
import { ContextMenuDir, IContextMenuItem } from "../ContextMenu";
import strings from "../../strings";
import { ImageView } from "../AvatarView";
import paths from "../../paths";
import { DescrView } from "../DescrView";
import menus from "../../menus";

interface ContestRowProps {
    item: IContest,
    isPublic?: boolean
    onEdit?: () => void
    onDelete?: () => void 
}

const contextMenu = [
    menus.edit, menus.delete
]

export default function ContestRow(props: ContestRowProps) {
    const navigate = useNavigate()
    function clickHandler() {
        if (props.isPublic) {
            const to = paths.public_contest_by_id(props.item.id || "")
            navigate(to)
        } else
            navigate(paths.contest_by_id(props.item.id || ""))
    }
    function contextMenuHandler(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.EDIT: 
                props.onEdit && props.onEdit()
                break;
            case menus.DELETE: 
                props.onDelete && props.onDelete()
                break;
        }
    }
    function onMenuItemVisisble(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.EDIT: 
                return !!props.onEdit
            case menus.DELETE: 
                return !!props.onDelete
            default:
                return false
        }
    }
    var status = "" 
    if (props.item.isAdmin)
        status = strings.lab_user_status_admin
    else if (props.item.isParticipating)
        status = strings.lab_user_status_contestant
    return (
        <div className="itemrow flex flex-row items-center gap-x-4" onClick={clickHandler}>
            <ImageView src={props.item.logo} size={PhotoSize.medium} className="flex-none"/>
            <div className="flex-auto" style={{textDecoration: props.item.isDeleted ? "line-through" : undefined }}>
                <span className="text-lg">{props.item.title}</span>
                <DescrView className="text-sm line-clamp-3" body={props.item.description}/>
                <span className="debugfield">{status}</span>
            </div>
            <ContextMenuButton className="flex-none"
                menu={contextMenu} dir={ContextMenuDir.FromRightTop}
                onClick={contextMenuHandler}
                onVisible={onMenuItemVisisble}
                />
        </div>
    )
}