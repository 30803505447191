import { useEffect, useState } from "react"
import { ContextMenuDir, IContextMenuItem } from "../../comp/ContextMenu"
import ContextMenuButton from "../../comp/ContextMenuButton"
import { EmptyView } from "../../comp/Loading"
import { Modal } from "../../comp/Modal"
import { useDragAndResize } from "../../hooks/useDragAndResize"
import { useModal } from "../../hooks/useModal"
import { SlideBox } from "./boxes/SlideBox"
import { EditFieldForm } from "./forms/EditFieldForm"
import { FIELD_TYPE_IMAGE, GalleryLayoutKind, ISlide, ISlideField, ISlideGalleryField, ISlideImageField, ISlideTextField, ISlideVoteField, SLIDE_SIZE, VOTE_NAME, nextSlideFieldKey } from "../../model/pptx"
import { IPoint, ImageScale } from "../../model/common"
import strings from "../../strings"
import { ListItemsReorder } from "../../comp/ListItems"
import { DependsDataContext } from "../../model/depends"
import { LabelWith } from "../../comp/Label"
import { OnGotoSlideById } from "./boxes/SlideVoteBox"
import { buildRects } from "../../services/PptxBuilder"

const MENU_ADD_TEXT = "add_text"
const MENU_ADD_IMAGE = "add_image"
const MENU_ADD_GALLERY = "add_gallery"
const MENU_ADD_VOTE = "add_vote"

const MENU = [
    { id: MENU_ADD_TEXT, title: strings.mi_add_text } as IContextMenuItem,
    { id: MENU_ADD_IMAGE, title: strings.mi_add_image } as IContextMenuItem,
    { id: MENU_ADD_GALLERY, title: strings.mi_add_gallery } as IContextMenuItem,
    { id: MENU_ADD_VOTE, title: strings.lab_add_vote } as IContextMenuItem,
]

interface SlideEditorProps {
    slide: ISlide
    context?: DependsDataContext
    className?: string
    templates?: ISlide[]
    onChange?: (nextSlide: ISlide) => void
    onOpen?: (slideKey: string) => void
    onGoto?: OnGotoSlideById
}

export function SlideEditor({ slide, context, templates, className, onChange, onOpen, onGoto }: SlideEditorProps) {
    const fieldModal = useModal<ISlideField>()
    const [fields, setFields] = useState<ISlideField[]>([])

    function updateFields(nextFields: ISlideField[]) {
        setFields(nextFields)
        onChange && onChange({ ...slide, fields: nextFields })
    }
    function updateField(item: ISlideField) {
        const nextFields = [...fields.filter(fld => fld.key !== item.key), item]
        updateFields(nextFields)
    }

    function deleteField(item: ISlideField) {
        const nextFields = [...fields.filter(fld => fld.key !== item.key)]
        setFields(nextFields)
        onChange && onChange({ ...slide, fields: nextFields })
    }

    const dragAndResize = useDragAndResize(
        (pt: IPoint) => {
            for (let i = fields.length - 1; i >= 0; i--) {
                const fld = fields[i]
                if ((fld.pos.x <= pt.x) && (pt.x <= fld.pos.x + fld.size.x) && (fld.pos.y <= pt.y) && (pt.y <= fld.pos.y + fld.size.y)) {
                    return fld
                }
            }
        },
        updateField
    )

    function menuClickHandler(mi: IContextMenuItem) {
        var text: ISlideTextField | undefined
        var image: ISlideImageField | undefined
        var gallery: ISlideGalleryField | undefined
        var vote: ISlideVoteField | undefined
        let fldName = "other"
        switch (mi.id) {
            case MENU_ADD_TEXT:
                fldName = "text"
                text = { text: "Текст" } as ISlideTextField
                break;
            case MENU_ADD_IMAGE:
                fldName = "image"
                image = { src: "", scale: ImageScale.cover } as ISlideImageField
                break;
            case MENU_ADD_GALLERY:
                fldName = "gallery"
                gallery = { items: "", kind: GalleryLayoutKind.AUTO } as ISlideGalleryField
                break;
            case MENU_ADD_VOTE:
                fldName = "vote"
                vote = { items: [], name: VOTE_NAME }
                break;
            default:
                return
        }
        const field = {
            key: nextSlideFieldKey(fldName, slide.id),
            pos: { x: 10, y: 10 }, size: { x: 80, y: 80 },
            image, text, gallery, vote
        } as ISlideField
        setFields([...fields, field])
        fieldModal.openModal(field)
    }

    function removeTemplateHandler() {
        onChange && onChange({ ...slide, template: undefined })
    }

    async function breakImagesHandler() {
        const field = fieldModal.modal
        if (field?.gallery) {
            const poses = await buildRects(field, field.gallery)
            let images = poses.map(pos => (
                {
                    key: nextSlideFieldKey(FIELD_TYPE_IMAGE, slide.id),
                    pos: pos.pos,
                    size: pos.size,
                    image: { src: pos.src, scale: ImageScale.cover }
                } as ISlideField
            ))
            const nextFields = [...fields.filter(fld => fld.key !== field.key), ...images]
            updateFields(nextFields)
        }
        fieldModal.closeModal()
    }

    useEffect(() => setFields(slide.fields), [slide])
    let slideBack = "white"
    let slideTextColor = "black"
    let slideWidth = SLIDE_SIZE.x
    let slideHeight = SLIDE_SIZE.y
    let canEdit = onChange && (!slide.template || slide.isTemplate)

    let outsides = slide.fields.filter(e => e.vote?.outside)
    let outsidesH = 0
    outsides.forEach(e => outsidesH = Math.max(outsidesH, e.size.y))
    return (
        <>
            {(onOpen && slide.template && !slide.isTemplate) &&
                <div className="flex flex-row items-center px-4 py-2 gap-2">
                    <span className="flex-auto">Слайд связан c шаблоном</span>
                    <button className="redbutton" onClick={removeTemplateHandler}>Открепить от шаблона</button>
                    <button className="textbutton" onClick={() => slide.template && onOpen(slide.template)}>Показать шаблон</button>
                </div>
            }
            {onChange && !slide.template && !slide.isTemplate &&
                <div className="flex flex-row items-center px-4 py-2 gap-2">
                    <span className="flex-auto">Связать c шаблоном</span>
                    <select className="textfield"
                        value={slide.template || ""} onChange={e => onChange({ ...slide, template: e.target.value })}>
                        <option value="">Нет шаблона</option>
                        {templates?.map(templ => 
                            <option key={templ.id} value={templ.name}>{templ.name}</option>
                        )}
                    </select>
                </div>
            }
            <div className="flex flex-row">
                <div className={"drop-shadow rounded-lg relative select-none overflow-hidden " + (className || "")}
                    onMouseDown={canEdit ? dragAndResize.onMouseDown : undefined}
                    onMouseMove={canEdit ? dragAndResize.onMouseMove : undefined}
                    onMouseLeave={canEdit ? dragAndResize.onMouseUp : undefined}
                    onMouseUp={canEdit ? dragAndResize.onMouseUp : undefined}
                    style={{
                        width: slideWidth,
                        height: slideHeight,
                        minWidth: slideWidth,
                        minHeight: slideHeight,
                        color: slideTextColor,
                        backgroundColor: slideBack
                    }}>

                    {onChange &&
                        <span className="debugfield px-2 absolute">#{slide.id}</span>
                    }

                    {fields.length === 0
                        ? <EmptyView className="absolute-center" text="Пустой слайд" />
                        : fields.filter(e => !e.vote?.outside).map(fld =>
                            <SlideBox key={fld.key}
                                field={fld}
                                selected={dragAndResize.selected?.key === fld.key}
                                onGoto={onGoto}
                                isTemplate={slide.isTemplate === true}
                                onDblClick={onChange ? fieldModal.openModal : undefined}
                                pos={dragAndResize.dragging === fld || dragAndResize.resize === fld ? dragAndResize.pos : fld.pos}
                                size={dragAndResize.dragging === fld || dragAndResize.resize === fld ? dragAndResize.size : fld.size}
                            />
                        )}
                    {onChange &&
                        <ContextMenuButton className="top-2 right-2 absolute"
                            menu={MENU}
                            dir={ContextMenuDir.FromRightTop}
                            onClick={menuClickHandler} />
                    }
                    {(onChange && !canEdit) &&
                        <div className="fixed bg-black/10 left-0 top-0 right-0 bottom-0 z-40" onClick={() => { }}></div>
                    }

                </div>
                {onChange && canEdit &&
                    <LabelWith text="Порядок отрисоки" className="items-end" >
                        <ListItemsReorder
                            items={fields}
                            onReorder={next => updateFields(next)}
                            getKey={item => item.key}
                            render={fld =>
                                <SlideFieldRow field={fld} key={fld.key}
                                    selected={fld === dragAndResize.selected}
                                    onClick={e => dragAndResize.setSelected(e)}
                                    onDblClick={fieldModal.openModal} />
                            }
                        />
                    </LabelWith>
                }
            </div>
            {outsides.length > 0 &&
                <div className="relative mt-2" style={{ height: outsidesH }}>
                    {outsides.map(fld =>
                        <SlideBox key={fld.key}
                            field={fld}
                            selected={dragAndResize.selected?.key === fld.key}
                            onGoto={onGoto}
                            isTemplate={slide.isTemplate === true}
                            onDblClick={onChange ? fieldModal.openModal : undefined}
                            pos={{ x: fld.pos.x, y: 0 }}
                            size={fld.size}
                        />
                    )}
                </div>
            }

            {fieldModal.modal &&
                <Modal title="" onClose={fieldModal.closeModal}>
                    {fieldModal.modal &&
                        <EditFieldForm
                            context={context}
                            field={fieldModal.modal}
                            isTemplate={slide.isTemplate || false}
                            onSubmit={updateField}
                            onDelete={deleteField}
                            onBreakImages={slide.isTemplate ? undefined : breakImagesHandler}
                            onClose={fieldModal.closeModal} />
                    }
                </Modal>
            }
        </>
    )
}

interface SlideFieldRowProps {
    field: ISlideField
    selected?: boolean
    onClick?: (item: ISlideField) => void
    onDblClick?: (item: ISlideField) => void
}

function SlideFieldRow({ field, selected, onClick, onDblClick }: SlideFieldRowProps) {
    return (
        <div className={"itemrow max-w-[120px] " + (selected ? "text-white" : "text-disabled")}
            onClick={onClick ? () => onClick(field) : undefined}
            onDoubleClick={onDblClick ? () => onDblClick(field) : undefined}>
            <span className="text-sm text-ellipsis line-clamp-2">{field.key}</span>
        </div>
    )
}