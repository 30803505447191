import { AxiosError, AxiosResponse } from "axios";
import { Dispatch } from "redux";
import { AuthAction, AuthActionTypes } from "../../model/auth";
import { IUser, dbid } from "../../model/models";
import { AuthResponse, ErrResponse } from "../../model/response";
import AuthService from "../../services/AuthService";
import JuryService from "../../services/JuryService";
import { SubmitterService } from "../../services/SubmitterService";
import UserService from "../../services/UserService";
import strings from "../../strings";
import { KEY_TOKEN } from "../reducers/authReducer";

function authSuccess(dispatch: Dispatch<AuthAction>, resp: AxiosResponse<AuthResponse>) {
    localStorage.setItem(KEY_TOKEN, resp.data.accessToken)

    dispatch({
        type: AuthActionTypes.SUCCESS,
        payload: resp.data.user
    })
}

function updateUserSuccess(dispatch: Dispatch<AuthAction>, resp: AxiosResponse<IUser>) {

    dispatch({
        type: AuthActionTypes.SUCCESS,
        payload: resp.data
    })
}

function error(dispatch: Dispatch<AuthAction>, e: unknown) {
    const err = e as AxiosError<ErrResponse>
    console.error(err);
    dispatch({
        type: AuthActionTypes.ERROR,
        payload: err.response?.data?.message || strings.err_auth
    })
}

export const loginAction = (email: string, password: string) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await AuthService.login(email, password);

            authSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const juryLoginAction = (contestId: dbid, username: string, password: string) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await JuryService.login(contestId, username, password)

            authSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const submitterLoginAction = (contestId: dbid, username: string, password: string) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await SubmitterService.login(contestId, username, password)

            authSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const checkJuryAction = () => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await JuryService.check()

            authSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const checkSubmitterAction = () => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await SubmitterService.check()

            authSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const uploadAvatarAction = (file: File) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            const resp = await UserService.uploadAvatar(file)
            updateUserSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const registerAction = (fullname: string, email: string, password: string, registrationCode: string | undefined) => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await AuthService.register(fullname, email, password, registrationCode);

            authSuccess(dispatch, resp)
        } catch (e) {
            error(dispatch, e)
        }
    }
}

export const checkUserAction = () => {
    return async (dispatch: Dispatch<AuthAction>) => {
        try {
            dispatch({ type: AuthActionTypes.AUTHORIZATION })

            const resp = await AuthService.check()

            authSuccess(dispatch, resp)
        } catch (e) {
            // localStorage.removeItem(KEY_USER)
            error(dispatch, e)
            // console.log("Auth error", e)
            // logoutAction()
        }
    }
}

export const logoutAction = () => {
    return async (dispatch: Dispatch<AuthAction>) => {
        dispatch({ type: AuthActionTypes.LOGOUT })
    }
}