import { Link } from 'react-router-dom';
import { Navigation } from '../../comp/Navigation';
import strings from '../../strings';
import './index.css';

const primary = "#5A5C91"
const secondary = "#474972"

interface IPromotedPageProps {
    logo?: string
    headerFooterOff?: boolean
    onRegisterClick?: () => void
}

export function PromotedPage({ logo, headerFooterOff, onRegisterClick }: IPromotedPageProps) {
    return (
        <>
            {!headerFooterOff && <Navigation
                logo={logo} title='' titleSmall=''
                background={primary} />}
            <div className="promoted flex flex-col bg-white text-black">
                <Section1 onRegisterClick={onRegisterClick} />
                <Section2 />
                <Section3 />
                <Section4 onRegisterClick={onRegisterClick} />
            </div>
            {!headerFooterOff &&
                <>
                    <PromotedFooter
                        onRegisterClick={onRegisterClick}
                        style={{
                            background: primary,
                            color: "white"
                        }} />
                    {/* <Footer style={{
                        background: primary,
                        color: "rgba(256,256,256,0.6)"
                    }} /> */}
                </>
            }
        </>
    )
}

function Section1({ onRegisterClick }: IPromotedPageProps) {
    return (
        <div className="sect flex flex-col relative pt-[100px] pb-[40px] gap-[48px]">
            <object data="/img/promoted/branch.svg"
                className='absolute branchLeft' />
            <object data="/img/promoted/snowflake.svg"
                className='absolute left-[120px] top-[20px] md:left-[360px] md:top-[60px]'
                style={{
                    width: 60,
                    // left: 40,
                    // top: 60
                }} />
            <div className='sectText' style={{ overflow: "visible" }}>
                <span className='h1'>Новогодний</span>
                <span className='h1'><span className='whitespace-nowrap'>Екатеринбург –</span> 2025</span>
                <div className='h3 mt-[30px] md:mt-[56px]'><span className='whitespace-nowrap'>02.12.2024 –</span> 21.01.2025</div>
                <p className='txt mt-[30px] md:mt-[40px]'>Традиционный конкурс «Новогодний Екатеринбург» начинает прием заявок. Заявки на участие принимаются со 2 по 23 декабря</p>

                <div className='mt-[40px] md:mt-[53px] '>
                    <button className="button justify-self-center"
                        style={{ background: primary }}
                        onClick={onRegisterClick}>Зарегистрироваться</button>
                </div>
            </div>
            <div className='sectImage'>
                <object data="/img/promoted/snowflake.svg"
                    className='absolute'
                    style={{
                        width: 70,
                        left: 20,
                        top: -10
                    }} />
                <div className='sectImageOut' >
                    <object className='absolute w-full h-full aspect-square' data="/img/promoted/tree_decor1.svg" />
                </div>
            </div>
            <object data="/img/promoted/branch.svg"
                className='absolute branchRight' />
        </div>
    );
}

function Section2() {
    return (
        <div className="sect flex flex-col-reverse relative pb-[100px] gap-[58px] " style={{ paddingTop: 40 }}>
            <div className='sectImage'>
                <div className='relative w-[300px] md:w-[500px] xl:w-[672px] aspect-square' >
                    <object className='absolute w-full h-full aspect-square' data="/img/promoted/tree_decor2.svg" />
                </div>
            </div>
            <div className="sectText">
                <div className='h2 borderBottom whitespace-nowrap'>О конкурсе</div>
                <div className='mt-8 txt'>Традиционный конкурс <span className='promoted'>«Новогодний <span className='whitespace-nowrap'>Екатеринбург –</span> 2025»</span> пополнился новой номинацией для горожан и гостей уральской столицы. Она называется «Лучшая фоторабота «Зимний Екатеринбург».</div>
                <div className='mt-8 txt'>В этом году участниками конкурса, который стартует <span className='promoted'>2 декабря</span>, могут стать не только предприятия и организации города, задача <span className='whitespace-nowrap'>которых –</span> украсить в новогоднем стиле свой фасад здания, витрину, входную группу или придомовую территорию, но и жители и гости города Екатеринбурга, запечатлевшие на фото эту красоту.</div>
                <div className='mt-8 txt'>С более подробной информацией можно ознакомиться в <a className='promoted' href="https://екатеринбург.рф/официально/документы/постановления/п_2024/44973">Постановлении</a> о проведении конкурса «Новогодний <span className='whitespace-nowrap'>Екатеринбург – 2025»</span>.</div>
            </div>
            <object data="/img/promoted/snowflake.svg "
                className='absolute-x-center'
                style={{
                    width: 50,
                    bottom: 0
                }} />
        </div>
    );
}

function Section3() {
    return (
        <div className="sect pt-[90px] pb-[20px] md:pt-[240px] flex flex-col justify-start" >
            <object data="/img/promoted/branch.svg"
                className='absolute branchLeft' />
            <object data="/img/promoted/branch.svg"
                className='absolute branchRight' />
            <object data="/img/promoted/snowflake.svg"
                className='absolute hideOnSmall'
                style={{
                    width: 140,
                    right: 0,
                    top: 0
                }} />

            <div className="flex-1 xxl:flex-none flex flex-col items-center relative w-full">
                <div className='h2 borderBottom self-start ' >Сроки проведения</div>
                {/* flex flex-col md:flex-row md:flex-wrap xxl:flex-nowrap xl:w-min justify-around gap-[30px] py-[77px] */}
                <div className='py-[77px] gap-[30px] flex flex-col md:flex-row md:flex-wrap md:justify-center'>
                    <TextBlock num="1" text="До 14.01.2025 экспертная комиссия отберет по пять претендентов в каждой номинации, праздничное оформление которых окажется наиболее интересным" />
                    <TextBlock num="2" text="Жюри определит победителей и призеров, перечень которых будет объявлен на награждении" />
                    <TextBlock num="3" text="Награждение победителей и призеров конкурса состоится в период с 22 по 31 января 2025 года в торжественной обстановке" />
                </div>
            </div>
        </div>
    );
}

function TextBlock({ num, text }: { num: string, text: string }) {
    return (
        <div className='textblock flex flex-col'
            style={{ border: `4px solid ${secondary}` }}>
            <span className='num'
                style={{ color: secondary }}>{num}</span>
            <span className='mt-[28px] md:mt-[45px] txt'>{text}</span>
        </div>
    );
}

function Section4({ onRegisterClick }: IPromotedPageProps) {
    return (
        <div className="sect sec4 flex flex-col md:flex-row relative"
            style={{
                // marginRight: 0, 
                // paddingRight: 0
            }}>
            <object data="/img/promoted/snowflake.svg"
                className='absolute z-0 hideOnSmall'
                style={{
                    width: 120,
                    right: 0,
                    bottom: 60
                }} />
            <object data="/img/promoted/snowflake.svg"
                className='absolute-x-center'
                style={{
                    width: 70,
                    top: 0
                }} />
            <div className="sect4Text">
                <object data="/img/promoted/snowflake.svg"
                    className='absolute hideOnSmall'
                    style={{
                        width: 80,
                        left: 0,
                        top: 0
                    }} />

                <h1 className='h2 pr-[150px] borderBottom whitespace-nowrap'>Как подать заявку?</h1>
                <p className='pt-[38px] txt'>Заявки на участие принимаются через сайт со 2 по 23 декабря. <a className='promoted' href='https://екатеринбург.рф/news/96317-novogodniy-ekaterinburg-2025-v-konkurs-dobavilas-novaya-nominatsiya'>Подробности подачи заявки</a></p>
                <p className='mt-[46px] txt'>Для участия в конкурсе необходимо правильно заполнить заявку, с указанием всех данных организации, юридического лица или индивидуального предпринимателя. Для ознакомления с материалами конкурса рекомендуем изучить <a className='promoted' href="https://екатеринбург.рф/официально/документы/постановления/п_2024/44973">Постановление о проведении конкурса</a></p>

                <button className={`button mt-[40px] md:mt-[60px] justify-self-center`}
                    style={{ background: primary }}
                    onClick={onRegisterClick}>Подать заявку</button>
            </div>

            <div className='sect4Image'>
                <img className='object-cover object-top object-left flex-none w-[671px]'
                    src="/img/promoted/register_form.svg" />
            </div>
        </div>
    );
}

interface IPromotedFooterProps {
    style?: React.CSSProperties
    onRegisterClick?: () => void
}

function PromotedFooter({ style, onRegisterClick }: IPromotedFooterProps) {
    return (
        <div className='px-big flex flex-col' style={style}>
            <div className='py-8 md:py-big flex flex-row justify-between items-center'>
                <div className='cursor-pointer pr-[33px] mr-[33px] md:pr-[50px] md:mr-[50px] whitespace-nowrap'
                    onClick={onRegisterClick}
                    style={{
                        borderRight: "2px solid white"
                    }}>Подать заявку</div>

                <Link className=''
                    to="https://екатеринбург.рф/news/96317-novogodniy-ekaterinburg-2025-v-konkurs-dobavilas-novaya-nominatsiya">Информация о конкурсе</Link>
                <span className='flex-auto'></span>
                <div className='cursor-pointer hidden lg:flex' onClick={() => { window.scrollTo(0, 0) }}>Новогодний Екатеринбург – 2025</div>
            </div>
            <p className='py-8 max-w-[500px] flex-auto text-white/40'>{strings.footer}</p>
        </div>
    );
}