import { useTitle } from "../hooks/useTitle"
import strings from "../strings"

export function ContactPage() {
    useTitle(strings.nav_contact)

    return (
        <div className='maincontainer flex flex-col pb-8'>
            <h1 className="header">{strings.nav_contact}</h1>
            <div className="text-xl mt-32">Связаться</div>
            <div>По вопросам проведения конкурса или иного вида сотрудничества на адрес электронной почты <b>vbabin@list.ru</b></div>
            <h2 className="mt-32 text-xl">Отказ от ответственности</h2>
            <div>Сайт доступен для использования в ознокомительных целях. Ваш бесплатный аккаунт может быть заблокирован без обяснения причин. Проведение официальных конкурсов требует согласования с разработчиком.</div>
        </div>
    );
}