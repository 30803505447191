import strings from '../strings';


export function InfiniteLoading({ className }: { className?: string }) {
    return (
        <div className={'loading ' + (className || "")}></div>
    )
}

export function LoadingProgress({ progress, max, className, align }: { progress: number, max?: number, className?: string, align?: string }) {
    const alignment = "justify-" + (align || "center")
    const realMax = max || 100
    const value = progress*100 / realMax
    return (
        <div className={alignment + " flex items-center select-none " + (className || "")}>
            <div className='loading-progress w-[300px] bg-primary2 overflow-hidden rounded-lg mx-4 my-2 '>
                <div className='loading-bar bg-onPrimary' 
                    style={{ width: `${value}%`, height: "100%" }}></div>
            </div>
        </div>
    )
}

interface LoadingProps {
    text?: string
    align?: string
    nopadding?: boolean
    className?: string
}

export function Loading({ text, align, nopadding, className }: LoadingProps) {
    const alignment = "justify-" + (align || "center")
    const padding = nopadding ? "" : "px-4 py-2"
    return (
        <div className={alignment + " " + padding + " flex items-center gap-x-2 select-none text-onPrimary " + (className || "")}>
            <InfiniteLoading/>
            { text || strings.msg_loading }
        </div> 
    );
}

export function EmptyView({ text, align, nopadding, className }: LoadingProps) {
    const alignment = "justify-" + (align || "center")
    const padding = nopadding ? "" : "px-4 py-2"
    return (
        <div className={alignment + " " + padding + " flex items-center select-none text-disabled " + (className || "")}>
            { text || strings.msg_empty }
        </div>
    );
}