import { IContest, IContestEntity, ITemplate, TemplateFieldType } from "../../model/models"
import strings from "../../strings"

export const MAX_SPAN = 10000

export interface ICell {
    row: number
    col: number
    span?: number
    value: string
}

export interface IRow {
    parent: number[]
    index: number
    cql?: string
    before?: IRow[]
    after?: IRow[]
    columns: ICell[]
}

export function times<T>(n: number, call: (index: number) => T): T[] {
    const res = new Array<T>()
    for(let i = 0; i < n; i++) {
        const t = call(i)
        res.push(t)
    }
    return res
}

export function rowSpans(row: IRow): number {
    var res = 0
    for (let i = 0; i < row.columns.length; i ++) {
        let cell = row.columns[i]
        let cellSpan = cell?.span || 1
        if (cellSpan === MAX_SPAN) 
            res += 1
        else
            res += cellSpan
    }
    return res
}

const CODE_A = 'A'.charCodeAt(0)
const CODE_Z = 'Z'.charCodeAt(0)
const CODE_SIZE = CODE_Z - CODE_A + 1

export function indexToAbc(n: number): string {
    var res = ""
    do {
        let n0 = n % CODE_SIZE
        res = String.fromCharCode(CODE_A + n0) + res
        n = Math.floor(n / CODE_SIZE)
    } while (n)
    return res
}


// class DocField<T> {
//     name: string
//     title: string

//     constructor(name: string, title: string) {
//         this.name = name
//         this.title = title
//     }
// }

// class DocObject<T> extends DocField<T> {
//     fields: DocField<T>[]

//     constructor(name: string, title: string, fields: DocField<T>[]) {
//         super(name, title)
//         this.fields = fields
//     }
// }

// type GetValue<T> = (o: any) => T | undefined

export class DocField {
    name: string
    title: string
    type: TemplateFieldType

    constructor(name: string, title: string, type: TemplateFieldType) {
        this.name = name
        this.title = title
        this.type = type
    }

    // value(): string {
    //     return ""
    // }
}

export class DocEnum extends DocField {
    enums: string[]

    constructor(name: string, title: string, enums: string[]) {
        super(name, title, TemplateFieldType.ENUM)
        this.enums = enums
    }
}

export class DocObject<T> extends DocField {
    fields: DocField[]

    constructor(name: string, title: string, fields: DocField[]) {
        super(name, title, TemplateFieldType.OBJECT)
        this.fields = fields
    }
}

export class DocArray<T> extends DocField {
    itemType: DocField
    
    constructor(name: string, title: String, count: number | undefined, itemType: DocField) {
        super(name, title + `[${count || ""}]`, TemplateFieldType.ARRAY)
        this.itemType = itemType
    }
}

export function mockDocData(contestObj: IContest) {
    const entityFields = [
        new DocField("title", strings.lab_title, TemplateFieldType.TEXT),
        new DocField("description", strings.lab_title, TemplateFieldType.TEXT),
    ]
    contestObj.template?.fields?.forEach( field => {
        var docField: DocField
        let fieldName = `fields.${field.name}`
        if (field.enums)
            docField = new DocEnum(fieldName, field.label, field.enums)
        else 
            docField = new DocField(fieldName, field.label, field.type)
        entityFields.push(docField)
    })

    const contestEntity = new DocObject("contest_entity", strings.lab_contest_entity, entityFields)

    const nomination = new DocObject("nomination", strings.lab_nomination, [
        new DocField("title", strings.lab_title, TemplateFieldType.TEXT),
        new DocField("description", strings.lab_title, TemplateFieldType.TEXT),
        new DocArray("entities", "Объекты", undefined, contestEntity)
    ])

    const contest = new DocObject<IContest>("contest", strings.lab_contest, [
        new DocField("title", strings.lab_title, TemplateFieldType.TEXT),
        new DocField("description", strings.lab_title, TemplateFieldType.TEXT),
        new DocField("logo", strings.lab_logo, TemplateFieldType.IMAGE)
    ])

    const allNominations = new DocArray("nominations", "Все номинации", contestObj.nominations, nomination)
    const allEntities = new DocArray("entities", "Все объекты", contestObj.entities, contestEntity)
    // nomination, contestEntity,     
    return [ contest, allNominations, allEntities ]
}