import { useForm } from "react-hook-form"
import strings from "../../strings"
import { LabelWith } from "../Label"
import { descrValidation, titleValidation } from "./validations"
import { IStage, VoteKind } from "../../model/models"
import { ComboKeyText, Combobox2 } from "../Combobox"

const KINDS: ComboKeyText<VoteKind>[] = [
    [ VoteKind.SINGLE, "Один из многих" ],
    [ VoteKind.MANY,   "Несколько из многих" ],
    [ VoteKind.PLACES, "Места" ],
    [ VoteKind.POINTS, "Баллы" ]
]

interface CreateStageFormProps {
    stage: IStage
    onSave: (next: IStage) => void
    onCancel: () => void
}

export function CreateStageForm({ stage, onSave, onCancel}: CreateStageFormProps) {
    const { register, formState: { errors }, watch,  handleSubmit } = useForm<IStage>({ defaultValues: stage })

    const voteKind = watch("voting.voteKind")// || VoteKind.SINGLE
    return (
        <form className="flex flex-col w-full" onSubmit={handleSubmit(onSave)}>
            <LabelWith text={strings.lab_title}
                error={errors.title}>
                <input className='textinput' 
                    placeholder={strings.lab_title}
                    {...register("title", titleValidation())} />
            </LabelWith>
            
            <LabelWith text={strings.lab_descr}
                error={errors.description}>
                <textarea className='textinput' 
                    placeholder={strings.lab_descr}
                    rows={5}
                    {...register("description", descrValidation())} />
            </LabelWith>
            <LabelWith text="Правила голосования">
                {/* <Combobox2 items={KINDS} {...register("voting.voteKind")}/> */}
                <select className='textfield' {...register("voting.voteKind")}>
                    {KINDS.map( knd =>
                        <option id={knd[0]} value={knd[0]}>{knd[1]}</option>
                    )}
                </select>
                {voteKind === VoteKind.MANY
                ? <input className='textinput mt-2' 
                    type="number"
                    placeholder="Макс кол-во выбранных номинантов"
                    {...register("voting.numbers", { 
                        required: true, min: 2, valueAsNumber: true,
                        
                    })} 
                    />
                : voteKind === VoteKind.PLACES 
                && <input className='textinput mt-2' 
                    type="number"
                    placeholder="Кол-во мест"
                    {...register("voting.numbers", { required: true, min: 2 })} 
                    />
                }
                
            </LabelWith>

            <div className='flex flex-row justify-end gap-2 pt-4'> 
                <button type='submit' 
                    className='greenbutton'>{strings.button_save}</button>
                {onCancel &&
                <button type='reset' 
                    className='textbutton' 
                    onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </form>
    )
}