import { useState } from "react"
import { INomination, dbid } from "../../model/models"
import DataService from "../../services/DataService"
import { TextInput, TextInputType } from "../TextInput"
import strings from "../../strings"

interface CreateNominationListProps {
    // items: string[]
    contestId: dbid
    onSave: (items: INomination[]) => void
    onClose: () => void
}

export function CreateNominationList({ contestId, onSave, onClose }: CreateNominationListProps) {
    const [items, setItems] = useState<string>("")
    async function saveHandler() {
        let nominations = items.split("\n").filter(s => !!s.trim()).map( title => {
            return { title, description: "" } as INomination
        })
        if (nominations.length === 0)
            return
        try {
            const resp = await DataService.createNominationList(contestId, nominations)
            onSave(resp.data)
        } catch(e) {
            console.error(e)
        }
        onClose()
    }
    return (
        <div className='flex flex-col space-y-2 w-full'>
            <TextInput type={TextInputType.multiline} 
                value={items} setValue={setItems} 
                placeholder={strings.msg_create_nominations}
                />

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton' onClick={saveHandler}>{strings.button_save}</button>
                {onClose &&
                <button type='reset' className='textbutton' onClick={onClose}>{strings.button_cancel}</button>
                }
            </div>
        </div>
    )
}