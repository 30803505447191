import api from "../api"
import { dbid, IStaticPage, IStaticPageHeader } from "../model/models"

export default class StaticPageService {
    static async create(staticPage: IStaticPage) {
        return api.post<IStaticPageHeader>(`/pages`, staticPage);
    }

    static async update(staticPage: IStaticPage) {
        return api.post(`/pages/${staticPage.id}`, staticPage);
    }

    static async delete(pageId: dbid) {
        return api.delete(`/pages/${pageId}`);
    }

    static async getStaticPage(pageId: dbid) {
        return api.get<IStaticPage>(`/pages/${pageId}`);
    }

    static async getPublicStaticPage(pageId: dbid) {
        return api.get<IStaticPage>(`/pages/public/${pageId}`);
    }

    static async getList(contestId: dbid) {
        return api.get<IStaticPageHeader[]>(`/pages/list/${contestId}`);
    }

}