import { useContext, useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { Checkbox } from "../../../comp/Checkbox"
import { ErrorMessage } from "../../../comp/ErrorMessage"
import { VoteContext } from "../../../context/VoteContext"
import { IVoteSubmit, VoteKind } from "../../../model/models"
import { ISlideVoteField } from "../../../model/pptx"
import VoteService from "../../../services/VoteService"
import { VoteManyView } from "./comp/VoteManyView"
import { VotePlacesView } from "./comp/VotePlacesView"
import { VotePointsView } from "./comp/VotePointsView"
import { VoteSingleView } from "./comp/VoteSingleView"

export type OnGotoSlideById = (id: string) => void

interface SlideVoteBoxProps {
    data: ISlideVoteField
    isTemplate: boolean
    onGoto?: OnGotoSlideById
}

export function SlideVoteBox({ data, isTemplate, onGoto }: SlideVoteBoxProps) {
    const voteContext = useContext(VoteContext)

    const [votes, setVotes] = useState<IVoteSubmit>(() =>
        voteContext?.result?.items[data.name] || {}
    )
    const votesRef = useRef<IVoteSubmit>(votes);
    // console.log("voteBox", data);

    async function voteSubmit(name: string, value: IVoteSubmit, force?: boolean) {
        console.log("voteSubmit", name, value);
        if (!voteContext?.result)
            return
        let prev = voteContext.result.items[name]
        if (!force && JSON.stringify(prev) === JSON.stringify(value))
            return
        try {
            const resp = await VoteService.presentationVote(voteContext.contestId,
                voteContext.result.id, name, value)
            if (resp.statusText === "OK") {
                voteContext.result.items[name] = value
                // toast("Результат голосования успешно сохранен!")
            }
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        votesRef.current = votes;
    }, [votes])
    useEffect(() => () => {
        voteContext && voteSubmit(data.name, votesRef.current || votes)
    }, [data])

    if (isTemplate) {
        return (
            <VoteSingleView items={data.items}
                votes={votes} setVotes={setVotes}
                onSubmit={voteContext && (ids => voteSubmit(data.name, ids, true))} />
        )
    } else {
        if (!voteContext?.rules) {
            return (
                <div className="w-full h-full flex flex-col overflow-y-auto">
                    <ErrorMessage message="Нет правил голосования" />
                    {data.items.map(m =>
                        <Checkbox key={m.id} value={false} setValue={() => { }} text={m.title} left disabled />
                    )}
                </div>
            )
        } else {
            switch (voteContext.rules.voteKind) {
                case VoteKind.PLACES: {
                    return (
                        <VotePlacesView items={data.items}
                            max={voteContext.rules.numbers || 2}
                            voteContext={voteContext}
                            votes={votes} setVotes={setVotes}
                            onGoto={onGoto}
                            onSubmit={voteContext && (ids => voteSubmit(data.name, ids, true))} />
                    )
                }
                case VoteKind.SINGLE: {
                    return (
                        <VoteSingleView items={data.items}
                            votes={votes} setVotes={setVotes}
                            onSubmit={voteContext && (ids => voteSubmit(data.name, ids, true))} />
                    )
                }
                case VoteKind.MANY: {
                    return (
                        <VoteManyView items={data.items} max={voteContext.rules.numbers || 2}
                            votes={votes} setVotes={setVotes}
                            onGoto={onGoto}
                            onSubmit={voteContext && (ids => voteSubmit(data.name, ids, true))} />
                    )
                }
                case VoteKind.POINTS: {
                    return (
                        <VotePointsView data={data}
                            votes={votes} setVotes={setVotes}
                            onGoto={onGoto}
                            onSubmit={voteContext && (ids => voteSubmit(data.name, ids, true))} />
                    )
                }
            }
        }
    }
}