interface DotsSliderViewProps {
    index: number
    count: number
    className?: string
    onDotClassName?: (index: number) => string | null 
}

export function DotsSliderView({ index, count, className, onDotClassName }: DotsSliderViewProps) {
    const dots = new Array(count).fill(1)
    return (
        <div className={"flex flex-row justify-center items-center py-2 gap-x-2 " + (className || "")}>
            {dots.map( (m, mIndex) =>
            <DotView key={mIndex} 
                className={ (onDotClassName && onDotClassName(mIndex)) || undefined }
                current={mIndex === index} 
                />
            )}
        </div>
    )
}

interface DotViewProps {
    current?: boolean
    className?: string
}

function DotView({ current, className }: DotViewProps) {
    if (current)
        return (
            <div className={"rounded-full " + (className || "bg-onPrimary") } style={{ width: "6px", height: "6px" }}></div>
        )
    else
        return (
            <div className={"rounded-full " + (className  || "bg-onPrimary") } style={{ width: "4px", height: "4px" }}></div>
        )
}