import { Modal } from '../comp/Modal';
import { useContests } from '../hooks/useItems';
import { IContest } from '../model/models';
import ContestRow from '../comp/rows/ContestRow';
import { CreateContest } from '../comp/forms/CreateContest';
import { useModal } from '../hooks/useModal';
import DataService from '../services/DataService';
import strings from '../strings';
import { ListUseItems } from '../comp/ListItems';
import { Label } from '../comp/Label';
import { useTitle } from '../hooks/useTitle';
import { CreateContestFromExamples } from '../comp/forms/CreateContestFromExamples';
import { useTypedSelector } from '../hooks/useTypedSelector';

export default function DashboardPage() {
    useTitle(strings.nav_dashboard)
    const { user } = useTypedSelector(state => state.auth);
    const contests = useContests()
    const createContestModal = useModal<IContest>()
    const createContestFromExampleModal = useModal<boolean>()

    function createHandler(item: IContest) {
        const exist = contests.items.find( contest => contest.id === item.id)
        if (exist) {
            contests.removeItem(exist)
        }
        contests.addItem(item)
        createContestModal.closeModal()
    }
    function createContestFromExampleHandler(item: IContest) {
        createContestFromExampleModal.closeModal()
        contests.addItem(item)
    }
    async function deleteHandler(item: IContest) {
        if (item.id && global.confirm(strings.confirm_delete_contest)) {
            try {
                const resp = await DataService.deleteContest(item.id)
                if (resp.statusText === "OK") {
                    contests.removeItem(item)
                }
            } catch(e) {

            } finally {

            }
        }
    }

    return (
        <div className="maincontainer flex flex-col">
            <h1 className="header">{strings.nav_dashboard}</h1>
            
            {user && !user.isLimited &&
            <div className="pt-big pb-4 flex flex-row gap-x-2">
                <button type='button' className="textbutton" 
                    onClick={ () => createContestModal.openModal({} as IContest) }>{strings.button_create_contest}</button>

                <button type='button' className="textbutton"
                    onClick={() => createContestFromExampleModal.openModal(true)}>Create from example</button>
            </div>
            }

            <Label text={strings.lab_contests}/>
            <ListUseItems useItems={contests}
                render={item =>
                <ContestRow key={ item.id } 
                    item={ item } 
                    onDelete={() => deleteHandler(item)}
                    onEdit={() => createContestModal.openModal(item)}
                    /> 
                }
                />
                
            { createContestModal.modal && 
            <Modal title={ createContestModal.modal ? strings.dlg_title_edit_contest : strings.dlg_title_create_contest} 
                onClose={ createContestModal.closeModal }>
                <CreateContest 
                    contest={createContestModal.modal}
                    onSave={ createHandler } 
                    onCancel={ createContestModal.closeModal }/>
            </Modal>}
            { createContestFromExampleModal.modal &&
            <Modal title={strings.dlg_title_create_contest} onClose={ createContestFromExampleModal.closeModal }>
                <CreateContestFromExamples 
                    onSubmit={ createContestFromExampleHandler } 
                    onCancel={ createContestFromExampleModal.closeModal }/>
            </Modal>}
        </div>
    );
}


