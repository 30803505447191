import { ISubmitter, IUser } from "./models"

export interface AuthReducerState {
    loading: boolean,
    isAuth: boolean,
    user?: IUser,
    jury?: IUser,
    submitter?: ISubmitter,
    error: string | null
}

export enum AuthActionTypes {
    AUTHORIZATION = "AUTH",
    // REGISTER = "REGISTER",
    LOGOUT = "LOGOUT",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR"
}

interface AuthorizationAction {
    type: AuthActionTypes.AUTHORIZATION,
}

interface LogoutAction {
    type: AuthActionTypes.LOGOUT,
}

interface SuccessAction {
    type: AuthActionTypes.SUCCESS,
    payload: IUser
}

interface ErrorAction {
    type: AuthActionTypes.ERROR,
    payload: string
}

export type AuthAction = AuthorizationAction | LogoutAction | SuccessAction | ErrorAction;