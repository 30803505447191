import api from "../api"
import { IMessage, ISendMessage, dbid } from "../model/models"

export default class MessageService {

    static async getMessage(messageId: dbid) {
        return api.get<IMessage>(`/messages/${messageId}`)
    }

    static async getInbox() {
        return api.get<IMessage[]>(`/messages/inbox`)
    }

    static async getSent() {
        return api.get<IMessage[]>(`/messages/sent`)
    }

    static async send(data: ISendMessage, contestId?: dbid, entityId?: dbid) {
        var params = ""
        if (contestId)
            params.addToken(`contestId=${contestId}`, "&")
        if (entityId)
            params.addToken(`entityId=${entityId}`, "&")
        
        return api.post<IMessage>(`/messages`.addToken(params, "?"), data)
    }
}