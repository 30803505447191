import React, { useState } from "react";
import { Controller, FieldError, SubmitHandler, useForm } from "react-hook-form";
import { NominationsSortDir, useNominations } from "../../hooks/useItems";
import { useModal } from "../../hooks/useModal";
import { IContestEntity, INomination, ITemplateField, dbid } from "../../model/models";
import DataService from "../../services/DataService";
import strings from "../../strings";
import { LabelWith } from "../Label";
import { ListItems } from "../ListItems";
import { Loading } from "../Loading";
import { Modal } from "../Modal";
import { EditField } from "../fields/EditField";
import NominationRow from "../rows/NominationRow";
import { EditNominationsContestEntity } from "./EditNominationsContestEntity";
import { descrValidation, titleValidation } from "./validations";
import { parserClassName, parseStyleCss } from "../../extends/css";

interface CreateContestEntityProps {
    item: IContestEntity
    contestId: dbid

    submitterEmail?: string

    className?: string
    style?: React.CSSProperties
    dialogStyle?: React.CSSProperties
    labelStyle?: React.CSSProperties
    fieldClassName?: string
    logo?: string
    title?: string
    allowDescription: boolean
    titleLabel?: string
    titleHint?: string
    submitStyle?: React.CSSProperties
    submitText?: string
    cancelStyle?: React.CSSProperties
    cancelText?: string

    fields: ITemplateField[] | undefined
    nominations: INomination[]
    maxNominations: number
    onSave: (item: IContestEntity) => void
    onCancel?: () => void
}

const FIELD_EMAIL = "email";

export function CreateContestEntity({ item, contestId, submitterEmail, className, style, dialogStyle, labelStyle, fieldClassName, logo, title, allowDescription, titleLabel, titleHint, submitStyle, submitText, cancelStyle, cancelText,
    fields, nominations, maxNominations,
    onSave, onCancel
}: CreateContestEntityProps) {
    const emailFieldReadonly = !!submitterEmail;
    if (emailFieldReadonly) {
        item.fields = { ...item.fields || {}, [FIELD_EMAIL]: submitterEmail };
    }
    const {
        register, control, formState: { errors }, handleSubmit
    } = useForm<IContestEntity>({ defaultValues: item })
    const [saving, setSaving] = useState(false);
    const [nominationsError, setNominationsError] = useState<FieldError>()
    const [nominationsIds, setNominationsIds] = useState<dbid[]>(() => item.nominationIds || (nominations.length == 1 ? [nominations[0].id] : []));
    const nominateModal = useModal<boolean>()

    const onSubmit: SubmitHandler<IContestEntity> = async (data) => {
        if (nominationsIds.length > maxNominations) {
            setNominationsError({ message: strings.errorMaxNominations(maxNominations) } as FieldError)
            return
        }
        if (nominationsIds.length == 0) {
            setNominationsError({ message: strings.errorMinNominations } as FieldError)
            return
        }
        data.id = data.id || item.id;
        data.nominationIds = nominationsIds;
        setSaving(true);
        try {
            const resp = await DataService.saveContestEntity(contestId, data)
            onSave(resp.data);
        } catch (e) {
            console.error(e)
        } finally {
            setSaving(false);
        }
    }

    function saveNominations(nextIds: dbid[]) {
        nominateModal.closeModal()
        setNominationsIds(nextIds)
        if (nextIds.length > maxNominations) {
            setNominationsError({ message: strings.errorMaxNominations(maxNominations) } as FieldError)
        } else if (nextIds.length == 0) {
            setNominationsError({ message: strings.errorMinNominations } as FieldError)
        } else {
            setNominationsError(undefined)
        }
    }

    return (
        <>
            {saving ? <Loading />
                : <form onSubmit={handleSubmit(onSubmit)} className={'flex flex-col space-y-2 w-full ' + (className || "")}
                    style={style}>
                    {logo &&
                        <div className="flex flex-row justify-center">
                            <img className="max-w-[250px] max-h-[100px]" src={logo} />
                        </div>
                    }
                    {title &&
                        <div className="text-center text-[24px] pb-6">{title}</div>
                    }

                    <LabelWith text={titleLabel || strings.lab_entity_name} error={errors.title}
                        labelStyle={labelStyle}>
                        <input className="textinput"
                            style={parseStyleCss(fieldClassName)}
                            placeholder={titleHint || titleLabel || strings.lab_entity_name}
                            {...register("title", titleValidation())} />
                    </LabelWith>
                    {allowDescription &&
                        <LabelWith text={strings.lab_entity_desc} error={errors.description}
                            labelStyle={labelStyle}>
                            <textarea className={fieldClassName || "textinput"} rows={3}
                                placeholder={strings.lab_entity_desc}
                                {...register("description", descrValidation())} />
                        </LabelWith>}
                    {fields?.filter(fld => !fld.deprecated).map(fld =>
                        <Controller key={fld.name}
                            name={`fields.${fld.name}`}
                            control={control}
                            rules={{
                                required: fld.required,
                                pattern: fld.regex ? {
                                    value: new RegExp(fld.regex),
                                    message: strings.msg_incorrect + " " + fld.hint && ` ${fld.hint}`
                                } : undefined
                            }}
                            render={({ field: { onChange, onBlur, value } }) =>
                                <EditField
                                    className={fieldClassName}
                                    labelStyle={labelStyle}
                                    buttonStyle={{ background: submitStyle?.background }}
                                    contestId={contestId} entityId={item?.id}
                                    // uploads={uploads}
                                    error={errors.fields && errors.fields[fld.name]}
                                    value={value} onChange={fld.name == FIELD_EMAIL && emailFieldReadonly ? undefined : onChange} onBlur={onBlur}
                                    field={fld}
                                />
                            }
                        />
                    )}

                    <LabelWith text={strings.lab_nominations} error={nominationsError}
                        labelStyle={labelStyle}>
                        <ListItems items={nominations.filter(e => nominationsIds.indexOf(e.id) >= 0)}
                            render={item =>
                                <NominationRow key={item.id} item={item} />
                            }
                        />
                        {nominations.length > 1 &&
                            <div className="flex flex-row justify-center">
                                <div className="textbutton"
                                    style={{ background: submitStyle?.background }}
                                    onClick={() =>
                                        nominateModal.openModal(true)
                                    }>{strings.button_entity_change_nominations}</div>
                            </div>
                        }
                    </LabelWith>

                    <div className='flex flex-row justify-end gap-2 pt-4'>
                        <button type='submit' className='greenbutton'
                            style={submitStyle}
                        >{submitText || strings.button_save}</button>
                        {onCancel &&
                            <button type='reset' className='textbutton'
                                style={cancelStyle}
                                onClick={onCancel}
                            >{cancelText || strings.button_cancel}</button>
                        }
                    </div>
                    {nominateModal.modal &&
                        <Modal title={strings.dlg_title_edit_entity_nominations}
                            style={dialogStyle} buttonStyle={{ background: submitStyle?.background }}
                            onClose={nominateModal.closeModal} >
                            <EditNominationsContestEntity
                                style={{ ...style, borderTopLeftRadius: style?.borderRadius, borderTopRightRadius: style?.borderRadius }}
                                submitStyle={{ background: submitStyle?.background }}
                                cancelStyle={{ background: cancelStyle?.background }}
                                nominations={nominations}
                                nominationIds={nominationsIds}
                                maxNominations={maxNominations}
                                onSave={saveNominations}
                                onCancel={nominateModal.closeModal}
                            />
                        </Modal>
                    }
                </form >}
        </>
    );
}