import { IFields, ITemplateField, TemplateFieldType, dbid } from "../../model/models"
import strings from "../../strings"
import { LabelWith } from "../Label"
import { EmptyView } from "../Loading"
import { TextField } from "../TextField"
import { EditFileField } from "./EditFileField"
import { EditImageField } from "./EditImageField"
import { EditImagesField } from "./EditImagesField"

export type FieldDragImageKind = "start" | "end" | "enter"
export type OnFieldDragImage = (field: string, src: string, e: React.DragEvent, eventKind: FieldDragImageKind) => void

interface FieldValueProps {
    contestId: dbid
    entityId?: dbid
    values: IFields
    field: ITemplateField
    className?: string
    onDragImage?: OnFieldDragImage
}

export function FieldValue({ className, values, field, contestId, entityId, onDragImage }: FieldValueProps) {
    let value = values[field.name]
    switch (field.type) {
        case TemplateFieldType.TEXT:
            return (
                <TextField className={className}
                    label={field.label}
                    emptyPlaceholder={strings.msg_empty}
                    text={value} />
            )
        case TemplateFieldType.ENUM:
            return (
                <TextField className={className}
                    label={field.label}
                    emptyPlaceholder={strings.msg_empty}
                    text={value} />
            )
        case TemplateFieldType.BOOL:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text="Not implemented yet!" />
                </LabelWith>
            )
        case TemplateFieldType.IMAGE:
            return (
                <EditImageField className="w-[300px]"
                    contestId={contestId}
                    entityId={entityId}
                    value={value}
                    label={field.label}
                    required={field.required} />
            )
        case TemplateFieldType.IMAGES:
            return (
                <EditImagesField className={className}
                    contestId={contestId}
                    entityId={entityId}
                    images={value?.split("\n") || []}
                    field={field}
                    onDragImage={onDragImage} />
            )
        case TemplateFieldType.VIDEO:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text="Not implemented yet!" />
                </LabelWith>
            )
        case TemplateFieldType.FILE:
            return (
                <EditFileField className={className}
                    contestId={contestId}
                    entityId={entityId}
                    value={value}
                    label={field.label}
                    required={field.required}/>
            )
        case TemplateFieldType.FILES:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text="Not implemented FILES yet!" />
                </LabelWith>
            )
        default:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text={`${strings.msg_unknown_field} ${field.type} name=${field.name}`} />
                </LabelWith>
            )
    }
}