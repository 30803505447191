import { useState } from "react"
import { useContestExamples } from "../../hooks/useItems"
import { EntityState, IContest, IContestExample } from "../../model/models"
import DataService from "../../services/DataService"
import strings from "../../strings"
import { Label } from "../Label"
import { ListUseItems } from "../ListItems"
import { Loading } from "../Loading"
import { ExampleContestRow } from "../rows/ExampleContestRow"

interface CreateContestFromExamplesProps {
    onSubmit: (item: IContest) => void
    onCancel: () => void
}

export function CreateContestFromExamples({ onSubmit, onCancel }: CreateContestFromExamplesProps) {
    const [ loading, setLoading ] = useState<boolean>()
    const items = useContestExamples()
    async function createHandler(item: IContestExample, state: EntityState) {
        setLoading(true)
        try {
            const resp = await DataService.createContestFromExample(item, state)
            onSubmit(resp.data)
        } catch(e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className='flex flex-col w-full'>
            <Label text="Список примеров"/>
            { loading 
            ? <Loading />
            : <>
            <ListUseItems useItems={items}
                render={item => 
                    <ExampleContestRow key={item.id} item={item} onSubmit={createHandler}/>
                }
                />
            <div className='flex flex-row justify-end pt-4'>
                <button type='button' className="textbutton" 
                    onClick={onCancel}>{strings.button_close}</button>
            </div>
            </>
            }
        </div>
    )
}
