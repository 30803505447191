import { EmptyView } from "../../../comp/Loading"
import { ISlideTextField, TextListKind } from "../../../model/pptx"

export const DEF_XLSX_FONT_SIZE = 11

interface SlideTextBoxProps {
    data: ISlideTextField
    isTemplate: boolean
}

export function SlideTextBox({ data }: SlideTextBoxProps) {
    if (data.text) {
        switch (data.list) {
            case TextListKind.BULLETED: 
            return (
                <ul 
                    style={{
                        textAlign: data?.align || "left",
                        listStyleType: "disc",
                        // justifyContent: data?.align || "left",
                        justifySelf: data?.align || "left",
                        // alignItems: data?.verticalAlign || "start",
                        alignSelf: data?.verticalAlign || "start",
                        fontSize: data.style?.fontSize || `${DEF_XLSX_FONT_SIZE}px`,
                        fontWeight: data.style?.bold ? "bold" : undefined,
                        fontStyle: data.style?.italic ? "italic" : undefined,
                        color: data.style?.color,
                        background: data.style?.background,
                        textDecoration: 
                            data.style?.underline && data.style?.strike ? "underline line-through" 
                            : data.style?.underline ? "underline " 
                            : data.style?.strike ? "line-through" 
                            : "",
                        // paddingLeft: "revert"
                    }}>
                    {data.text.split("\n").map((s, n) => 
                    <li key={n}>• {s}</li>
                    )}
                </ul>
            )
            case TextListKind.LAYOUT:
                return <></>
            case TextListKind.NUMBERED: 
                return (
                    <ul 
                        style={{
                            textAlign: data?.align || "left",
                            // justifyContent: data?.align || "left",
                            justifySelf: data?.align || "left",
                            // alignItems: data?.verticalAlign || "start",
                            alignSelf: data?.verticalAlign || "start",
                            fontSize: data.style?.fontSize || `${DEF_XLSX_FONT_SIZE}px`,
                            fontWeight: data.style?.bold ? "bold" : undefined,
                            fontStyle: data.style?.italic ? "italic" : undefined,
                            color: data.style?.color,
                            background: data.style?.background,
                            textDecoration: 
                                data.style?.underline && data.style?.strike ? "underline line-through" 
                                : data.style?.underline ? "underline " 
                                : data.style?.strike ? "line-through" 
                                : "",
                        }}>
                        {data.text.split("\n").map((s, index) => 
                        <li>{index+1}. {s}</li>
                        )}
                    </ul>
                )
            default: 
                return <span 
                    style={{
                        textAlign: data?.align || "left",
                        // justifyContent: data?.align || "left",
                        justifySelf: data?.align || "left",
                        // alignItems: data?.verticalAlign || "start",
                        alignSelf: data?.verticalAlign || "start",
                        fontSize: data.style?.fontSize || `${DEF_XLSX_FONT_SIZE}px`,
                        fontWeight: data.style?.bold ? "bold" : undefined,
                        fontStyle: data.style?.italic ? "italic" : undefined,
                        color: data.style?.color,
                        background: data.style?.background,
                        textDecoration: 
                            data.style?.underline && data.style?.strike ? "underline line-through" 
                            : data.style?.underline ? "underline " 
                            : data.style?.strike ? "line-through" 
                            : "",
                    }}
                    dangerouslySetInnerHTML={{ __html: data.text.replaceAll("\n", "<br/>") }}
                    />
        }
    } else return <EmptyView/>
}