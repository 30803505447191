import { CSSProperties, useState } from "react";
import { IMG_URL } from "../api";
import { PhotoSize } from "../model/models";

export type ObjectFit = "contain" | "cover" | "fill" | "none" | "scale-down"

interface ImageFBProps {
    src: string
    fallbackSrc: string
    main: string
    className?: string
    alt?: string
    style?: CSSProperties | undefined
    size?: PhotoSize
    objectFit?: ObjectFit
    width?: string
    // onDrag?: DragItemHandler<string>
    onDragStart?: (e: React.DragEvent<HTMLImageElement>) => void
    onDragEnter?: (e: React.DragEvent<HTMLImageElement>) => void
    onDragEnd?: (e: React.DragEvent<HTMLImageElement>) => void
    onClick?: (e: React.MouseEvent) => void
}

function ImageFB(props: ImageFBProps) {
    const [fallback, setFallback] = useState(false)
    var sizePx: string | undefined
    switch (props.size) {
        case PhotoSize.orig:
        case PhotoSize.large:
            sizePx = "500px"
            break;
        case PhotoSize.small:
            sizePx = "160px"
            break;
        case PhotoSize.medium:
            sizePx = "300px"
            break;
        default:
            sizePx = undefined
            break;
    }
    const style: CSSProperties = {
        width: sizePx, aspectRatio: 1.5,
        objectFit: props.objectFit || "cover"
    }

    function errorHandler(e: React.SyntheticEvent<HTMLImageElement, Event>) {
        if (!fallback && e.currentTarget.src !== props.fallbackSrc) {
            console.log(`Image fallback ${e.currentTarget.src}`, props.fallbackSrc)
            e.currentTarget.src = props.fallbackSrc
            setFallback(true)
        }
    }
    
    return (
        <img
            src={props.src}
            className={props.className}
            style={props.style || style}
            alt={props.alt}
            onError={errorHandler} 
            onClick={props.onClick}
            onDragStart={props.onDragStart}
            onDragEnter={props.onDragEnter}
            onDragEnd={props.onDragEnd}
            draggable={!!props.onDragStart}
            />
    );
}

export function extractImageSrc(src: string, size: PhotoSize) {
    if (src.startsWith("data:") || src.startsWith("http://") || src.startsWith("https://"))
        return src
    else {
        if (!src.endsWith(".jpg") && !src.endsWith(".jpeg") && !src.endsWith(".png"))
            return IMG_URL + src;
        else
            return IMG_URL + src.replace("/orig.", `/${size}.`);
    }
}

interface ImageExProps {
    src: string
    size: PhotoSize,
    className?: string,
    alt?: string
    objectFit?: ObjectFit
    style?: CSSProperties;
    // onDrag?: DragItemHandler<string>
    onDragStart?: (e: React.DragEvent<HTMLImageElement>) => void
    onDragEnter?: (e: React.DragEvent<HTMLImageElement>) => void
    onDragEnd?: (e: React.DragEvent<HTMLImageElement>) => void
    onClick?: (e: React.MouseEvent) => void
}

export function ImageEx(props: ImageExProps) {
    var src = extractImageSrc(props.src, props.size)
    return (
        <ImageFB className={props.className}
            src={src}
            fallbackSrc={IMG_URL + props.src}
            main={props.src}
            alt={props.alt}
            objectFit={props.objectFit}
            style={props.style}
            onClick={props.onClick}
            onDragStart={props.onDragStart}
            onDragEnter={props.onDragEnter}
            onDragEnd={props.onDragEnd}
            />
    )
}