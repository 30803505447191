import { Combobox2 } from "../comp/Combobox";
import { ErrorMessage } from "../comp/ErrorMessage";
import { IconButton } from "../comp/Icon";
import { Label } from "../comp/Label";
import { ListUseItems } from "../comp/ListItems";
import { BottomLogoView } from "../comp/LogoView";
import ContestEntityRow from "../comp/rows/ContestEntityRow";
import { useGlobalState } from "../hooks/useGlobalState";
import { EntitySortDir, useContestEntities } from "../hooks/useItems";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { dbid } from "../model/models";
import strings from "../strings";
import { sortDirs } from "./views/EntitiesView";

export function SubmitterPage() {
    const { submitter } = useTypedSelector(state => state.auth);


    return (
        <div className="maincontainer flex flex-col w-full ">
            {submitter?.contestId ?
                <SubmitterEntitiesView contestId={submitter.contestId} />
                : <ErrorMessage message={strings.err_auth} />
            }

        </div>
    );
}


interface ISubmitterEntitiesViewProps {
    contestId: dbid
}

function SubmitterEntitiesView({ contestId }: ISubmitterEntitiesViewProps) {
    const [sort, setSort] = useGlobalState<EntitySortDir>("SubmitterPage.sort", EntitySortDir.CREATE_ASC)
    const items = useContestEntities(contestId, sort)
    return (
        <>
            <Label text={strings.lab_contest_entities} />
            <ListUseItems useItems={items}
                renderTools={() =>
                    <div className="flex flex-row gap-2 items-center justify-end w-full">
                        <Combobox2 value={sort} items={sortDirs} setValue={setSort} />
                        <IconButton name="refresh" onClick={() => items.reload()} />
                    </div>
                }
                render={e =>
                    <ContestEntityRow key={e.id} item={e} contestId={contestId} />
                }
            />
        </>
    );
}