
interface LogoViewProps {
    className?: string
    logo?: string
    size?: number
    disabled?: boolean
    alt?: string
    // draggable?: boolean
    // text?: string
}

export function LogoView({ className, logo, size, disabled, alt }: LogoViewProps) {
    const width = size || 60
    var icon = logo || (disabled ? "/icon2.svg" : "/icon3.svg")
    return <img src={icon} width={width} className={className} alt={alt} />
}

export function BottomLogoView() {
    return <div className="flex flex-col justify-center items-center px-8 py-10">
        <LogoView disabled size={30} />
    </div >
}