import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FieldError } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMG_URL, USE_API_SERVER_URL, WEB_URL } from "../api";
import { BackLink } from "../comp/BackLink";
import { Checkbox } from "../comp/Checkbox";
import { IContextMenuItem } from "../comp/ContextMenu";
import ContextMenuButton from "../comp/ContextMenuButton";
import { DescrView } from "../comp/DescrView";
import { ErrorMessage } from "../comp/ErrorMessage";
import { FieldDragImageKind, FieldValue } from "../comp/fields/FieldValue";
import { EditNominationsContestEntity, EditNominationsContestEntityWithLoad } from "../comp/forms/EditNominationsContestEntity";
import { ImgUploader } from "../comp/ImgUploader";
import { LabelWith } from "../comp/Label";
import { ListItems, ListUseItems } from "../comp/ListItems";
import { Loading } from "../comp/Loading";
import { BottomLogoView } from "../comp/LogoView";
import { Modal } from "../comp/Modal";
import NominationRow from "../comp/rows/NominationRow";
import { TextField } from "../comp/TextField";
import { useContest, useContestEntity, useNominationsFor, useStaticPage, useStaticPageForm } from "../hooks/useItems";
import { useModal } from "../hooks/useModal";
import { useTitle } from "../hooks/useTitle";
import menus from "../menus";
import { dbid, defaultRepliesTemplates, EntityState, IAttachmentFile, IContestEntity, IRepliesTemplates, IRepliesTemplatesVars, MAX_NOMINATION_COUNT, repliesTemplatesVars, TemplateFieldType } from "../model/models";
import paths from "../paths";
import DataService from "../services/DataService";
import strings from "../strings";
import { EntityHeaderView } from "./EntityForJuryPage";
import { CreateContestEntityWithLoadNominations } from "./views/EntitiesView";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { CreateContestEntity } from "../comp/forms/CreateContestEntity";

export function ContestEntityPage() {
    const params = useParams()
    const contestId = params["contestId"] || "0"
    const entityId = params["id"] || "0"
    const navigate = useNavigate()
    const { submitter } = useTypedSelector(state => state.auth);
    // const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()

    const { contest, error: contestError } = useContest(contestId)
    const { contestEntity, setContestEntity, reload } = useContestEntity(contestId, entityId)
    const { staticForm } = useStaticPageForm(
        contest?.staticPageId, contestEntity?.nominationIds, !contest?.isAdmin)
    // console.log("Form:", staticForm);

    const editModal = useModal<boolean>()
    const nominateModal = useModal<boolean>()
    // const textsModal = useModal<string[]>()
    const submitStateModal = useModal<EntityState>()

    const nominations = useNominationsFor(contestId, entityId)

    const onDrop = async (acceptedFiles: File[]) =>
        uploadDocHandler(acceptedFiles)
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const [isDragImgActive, setDragImgActive] = useState<boolean>()

    useTitle(strings.lab_contest_entity + (contestEntity ? " - " + contestEntity.title : ""))

    async function sendEntityHandler() {
        const email = prompt("Введите email адрес получателя", contest?.replyToEmail)
        if (!email) {
            return
        }
        try {
            await DataService.sendEntity(contestId, entityId, email)
        } catch (e) {
            console.error(e)
        }
    }
    async function deleteHandler() {
        if (global.confirm(strings.confirm_delete_contest_entity)) {
            try {
                const resp = await DataService.deleteContestEntity(contestId, entityId)
                if (resp.statusText === "OK") {
                    navigate(paths.contest_by_id(contestId))
                } else {
                    setError("Can't delete contestant")
                }
            } catch (e) {
                console.error(e)
                setError("Can't delete contestant")
            }
        }
    }
    async function submitHandler() {
        if (global.confirm(strings.confirm_submit_contest_entity)) {
            try {
                const resp = await DataService.submitContestEntity(contestId, entityId)
                setContestEntity(resp.data)
            } catch (e) {
                console.error(e)
                setError("Can't delete contestant")
            }
        }
    }
    async function submitStateHandler(state: EntityState, message: string) {
        submitStateModal.closeModal()
        try {
            const resp = await DataService.submitResultContestEntity(contestId, entityId, state, message || "")
            setContestEntity(resp.data)
        } catch (e) {
            console.error(e)
            setError("Can't delete contestant")
        }
    }
    function saveHandler() {
        reload()
        nominations.reload()
        editModal.closeModal()
    }
    async function saveNominations(nextIds: dbid[]) {
        // setLoading(true)
        try {
            const resp = await DataService.updateContesatantNominations(contestId, entityId, nextIds)
            // onSave(resp.data)
            const next = resp.data
            setContestEntity(next)
            // setNominations(next)
            nominations.reload()
            nominateModal.closeModal()
        } catch (e) {
            console.error(e)
            // let err = e as AxiosError            
        } finally {
            // setLoading(false)
        }
    }
    async function uploadDocHandler(files: File[]) {
        let file = files[0]
        if (!file || !contestEntity)
            return
        try {
            const resp = await DataService.uploadDoc(file, contestId, entityId)
            if (resp.statusText === "OK") {
                setContestEntity({
                    ...contestEntity,
                    attachments: [...(contestEntity.attachments || []), resp.data]
                })
            }
        } catch (e) {
            console.error(e)
        }
    }
    async function deleteDocHandler(file: IAttachmentFile) {
        if (!contestEntity || !global.confirm(strings.confirm_delete_doc))
            return
        try {
            const resp = await DataService.deleteDoc(file.filename, contestId, entityId)
            if (resp.statusText === "OK") {
                setContestEntity({
                    ...contestEntity,
                    attachments: (contestEntity?.attachments || []).filter(m => m.filename !== file.filename)
                })
            }
        } catch (e) {
            console.error(e)
        }
    }
    async function processDocHandler(file: IAttachmentFile) {
        if (!contestEntity)
            return
        try {
            const resp = await DataService.processEntityAttachment(contestId, entityId, file.filename)
            if (resp.statusText === "OK") {
                //     setContestEntity({...contestEntity, 
                //         attachments: (contestEntity?.attachments || []).filter( m => m.filename !== file.filename)
                // })
            }
        } catch (e) {
            console.error(e)
        }
    }

    async function updateTextsHandler(texts: string[]) {
        if (!contestEntity)
            return
        try {
            const resp = await DataService.updateContestEntity(contestId, entityId, {
                texts: texts
            })
            if (resp.statusText === "OK") {
                setContestEntity(resp.data)
            }
        } catch (e) {
            console.error(e)
        }
    }
    function dragImageHandler(field: string, src: string, e: React.DragEvent, dragKind: FieldDragImageKind) {
        e.dataTransfer.setData(TemplateFieldType.IMAGE, JSON.stringify({ src, field }))
        canEdit && setDragImgActive(dragKind !== "end")
    }
    async function dropImageHandler(e: React.DragEvent) {
        let s = e.dataTransfer.getData(TemplateFieldType.IMAGE)
        if (!s)
            return
        let o = JSON.parse(s)
        let field = o.field
        let src = o.src
        if (!field || !src)
            return

        let filename = global.prompt("Имя файла")
        // console.log(filename)
        if (filename === null)
            return
        if (!filename?.trim())
            return global.alert("Некорректное имя файла")
        let n = src.lastIndexOf(".")
        if (n > 0) {
            let ext = src.substring(n)
            filename = filename + ext
        }

        // setLoading(true)
        try {
            const resp = await DataService.movePhotoToAttachmentContestEntity(contestId, entityId, field, src, filename)
            if (resp.statusText === "OK") {
                setContestEntity(resp.data)
            }
        } finally {
            // setLoading(false)
        }
    }
    function onDragOverHandler(e: React.DragEvent) {
        e.dataTransfer.dropEffect = 'move'
    }

    const canEdit = contest?.isAdmin || contestEntity?.canEdit
    return (
        <div className="maincontainer w-full flex flex-col pb-big">
            <BackLink to={
                submitter ? paths.submitter :
                    paths.contest_by_id(contestId, contest?.isAdmin ? 8 : 2)
            }
                text={strings.lab_contest_entities} />

            {(contest && contestEntity)
                ? <>
                    <h1 className="text-3xl font-thin0 w-full text-center pb-big">{contest.title}</h1>
                    <EntityHeaderView
                        contest={contest}
                        entity={contestEntity}
                    />
                    <div className="flex flex-row justify-end text-disabled font-thin0 text-sm gap-4">
                        <span>{strings.lab_created}: {contestEntity.created ? new Date(contestEntity.created).toLocaleDateString() : "-"}</span>
                        <span>{strings.lab_edited}: {contestEntity.edited ? new Date(contestEntity.edited).toLocaleDateString() : "-"}</span>
                    </div>
                    <div className="flex flex-row justify-end text-disabled font-thin0 text-sm gap-4">
                        <span>Форма: {staticForm?.title || "Нет"}</span>
                    </div>

                    <LabelWith text={strings.lab_descr} >
                        <DescrView body={contestEntity.description} className="textfield" />
                    </LabelWith>
                    <TextField label={strings.lab_entity_state} text={contestEntity.state + ""} />

                    <LabelWith text={strings.lab_actions} >
                        <div className="flex flex-wrap gap-2 justify-end">
                            {canEdit &&
                                <button className="textbutton"
                                    onClick={() => editModal.openModal(true)}>{strings.mi_edit}</button>
                            }
                            {(contest?.isAdmin || contestEntity.canDelete) &&
                                <button className="redbutton"
                                    onClick={deleteHandler}>{strings.mi_delete}</button>
                            }
                            {contest.isAdmin &&
                                <button className="textbutton"
                                    onClick={sendEntityHandler}>Отправить на почту</button>
                            }
                        </div>
                    </LabelWith>
                    <LabelWith text="Изменить статус заявки" >
                        <div className="flex flex-wrap gap-2 justify-end">
                            {contest.isAdmin &&
                                <>
                                    <button className="greenbutton"
                                        onClick={() => submitStateModal.openModal(EntityState.APPROVED)}>{strings.mi_approve}</button>
                                    <button className="redbutton"
                                        onClick={() => submitStateModal.openModal(EntityState.CORRECTION_REQUIRED)}>{strings.mi_correct_required}</button>
                                    <button className="redbutton"
                                        onClick={() => submitStateModal.openModal(EntityState.REJECTED)}>{strings.mi_reject}</button>
                                </>
                            }
                            {contestEntity.canEdit && !contestEntity.isAdmin &&
                                <button className="greenbutton"
                                    onClick={submitHandler}>
                                    {contestEntity.state == EntityState.NOT_SUBMITTED
                                        ? strings.mi_submit : strings.mi_resubmit
                                    }
                                </button>
                            }
                        </div>
                    </LabelWith>

                    {contest?.template?.fields?.map(fld =>
                        <FieldValue key={fld.name}
                            contestId={contestId} entityId={contestEntity.id}
                            values={contestEntity.fields} field={fld}
                            onDragImage={dragImageHandler}
                        />
                    )}

                    <LabelWith text={strings.lab_nominated}>
                        <>
                            {nominations.items.length == 0
                                ? <ErrorMessage message={strings.errorMinNominations} />
                                : nominations.items.length > (contest.maxNominations || MAX_NOMINATION_COUNT)
                                && <ErrorMessage message={strings.errorMaxNominations(contest.maxNominations || MAX_NOMINATION_COUNT)} />
                            }
                            <ListUseItems useItems={nominations}
                                nobottom
                                render={item =>
                                    <NominationRow key={item.id} item={item} />
                                }
                            />
                            {canEdit &&
                                <div className="flex flex-row justify-end">
                                    <button className="textbutton"
                                        onClick={() => nominateModal.openModal(true)}>{strings.button_entity_change_nominations}</button>
                                </div>
                            }
                        </>
                    </LabelWith>

                    <LabelWith text={strings.lab_attachments}
                    >
                        <div {...(canEdit ? getRootProps() : {})}
                            className={`relative flex flex-col pb-2 pr-2 ${isDragActive ? "bg-success" : ""}`}>
                            {canEdit &&
                                <input {...getInputProps()} />
                            }
                            <ListItems items={contestEntity.attachments || []}
                                render={m =>
                                    <AttachmentFileRow key={m.filename} file={m}
                                        onDelete={canEdit ? deleteDocHandler : undefined}
                                        onProcess={canEdit ? processDocHandler : undefined} />
                                } />
                            {canEdit &&
                                <div className="flex flex-row justify-end gap-2">
                                    <ImgUploader multiple onUpload={files => uploadDocHandler(Array.from(files))} />
                                </div>
                            }
                            {isDragImgActive &&
                                <div className="bg-success/75 absolute left-0 right-0 top-0 bottom-0"
                                    onDrop={dropImageHandler}
                                    onDragOver={onDragOverHandler}
                                // onDragEnter={onDragEnterHandler}
                                // onDragLeave={onDragLeaveHandler}
                                >
                                </div>
                            }
                        </div>
                    </LabelWith>

                    {/* {contestEntity.texts &&
                        <TextField label={strings.lab_texts_extracted}
                            multiline
                            text={contestEntity.texts.join("\n")}
                            buttonIcon="edit"
                            buttonOnClick={() => textsModal.openModal(contestEntity.texts || [])}
                        />
                    } */}
                </>
                : (error || contestError)
                    ? <ErrorMessage message={error || contestError || ""} />
                    : <Loading />
            }
            <BottomLogoView />

            {contest && contestEntity && editModal.modal &&
                <Modal title={strings.dlg_title_edit_entity} onClose={editModal.closeModal} wide>
                    {staticForm?.nominations
                        ? <CreateContestEntity item={contestEntity}
                            contestId={contestId}
                            fields={staticForm?.fields || contest.template?.fields}
                            nominations={staticForm.nominations}

                            allowDescription={true}
                            maxNominations={contest.maxNominations || MAX_NOMINATION_COUNT}
                            onSave={saveHandler}
                            onCancel={editModal.closeModal} />
                        : <CreateContestEntityWithLoadNominations item={contestEntity}
                            contestId={contestId}
                            fields={staticForm?.fields || contest.template?.fields}

                            allowDescription={true}
                            maxNominations={contest.maxNominations || MAX_NOMINATION_COUNT}
                            onSave={saveHandler}
                            onCancel={editModal.closeModal}
                        />
                    }
                </Modal>
            }
            {contestId && nominateModal.modal &&
                <Modal title={strings.dlg_title_edit_entity_nominations}
                    onClose={nominateModal.closeModal} >
                    {staticForm?.nominations
                        ? <EditNominationsContestEntity
                            nominations={staticForm.nominations}
                            nominationIds={nominations?.items?.map(n => n.id || "0") || []}
                            maxNominations={contest?.maxNominations || MAX_NOMINATION_COUNT}
                            onSave={saveNominations}
                            onCancel={nominateModal.closeModal}
                        />
                        : <EditNominationsContestEntityWithLoad
                            contestId={contestId}
                            nominationIds={nominations?.items?.map(n => n.id || "0") || []}
                            maxNominations={contest?.maxNominations || MAX_NOMINATION_COUNT}
                            onSave={saveNominations}
                            onCancel={nominateModal.closeModal}
                        />}
                </Modal>
            }
            {/* {textsModal.modal &&
                <Modal title={strings.lab_texts_extracted} onClose={textsModal.closeModal} wide>
                    <EditTextsView
                        texts={textsModal.modal}
                        onSave={updateTextsHandler}
                        onCancel={textsModal.closeModal}
                    />
                </Modal>
            } */}
            {submitStateModal.modal && contest && contestEntity &&
                <Modal title={strings.lab_entity_state} onClose={submitStateModal.closeModal} >
                    <MessageStateForm state={submitStateModal.modal}
                        repliesTemplates={contest?.repliesTemplates}
                        vars={{
                            contestTitle: contest?.title,
                            entityTitle: contestEntity?.title,
                            entityUri: WEB_URL + paths.contest_entity_by_id(contest.id, contestEntity.id),
                            message: ""
                        }}
                        onSave={submitStateHandler}
                        onClose={submitStateModal.closeModal} />
                </Modal>
            }
        </div>
    )
}

// interface EditTextsViewProps {
//     texts: string[]
//     onSave: (texts: string[]) => void
//     onCancel: () => void
// }

// function EditTextsView({ texts, onSave, onCancel }: EditTextsViewProps) {
//     const [text, setText] = useState<string>(() => texts.join("\n"))
//     function saveHandler() {
//         onSave(text.split("\n").map(m => m.trim()))
//         onCancel()
//     }
//     return (
//         <div className="flex flex-col">

//             <textarea
//                 className="textinput h-96"
//                 placeholder=""
//                 value={text}
//                 onChange={e => setText(e.target.value)}
//             // onBlur={e => state.onBlur}
//             >{text}</textarea>

//             <div className='flex flex-row justify-end gap-2 pt-4'>
//                 <button type='submit' className='greenbutton'
//                     onClick={saveHandler}
//                 >{strings.button_save}</button>
//                 {onCancel &&
//                     <button type='reset' className='textbutton'
//                         onClick={onCancel}>{strings.button_cancel}</button>
//                 }
//             </div>
//         </div>
//     )
// }

const ATTACHMENT_FILE_MENU = [
    menus.delete, menus.copyUrl,
    { id: menus.PROCESS, title: strings.mi_process_attachement } as IContextMenuItem
]

interface AttachmentFileRowProps {
    file: IAttachmentFile
    allowCopy?: boolean
    onProcess?: (file: IAttachmentFile) => void
    onDelete?: (file: IAttachmentFile) => void
    // onCopy?: (file: IAttachmentFile) => void
}

export function AttachmentFileRow({ file, allowCopy, onDelete, onProcess }: AttachmentFileRowProps) {
    function onMenuHandler(mi: IContextMenuItem) {
        if (mi.id === menus.delete.id)
            onDelete && onDelete(file)
        else if (mi.id === menus.PROCESS)
            onProcess && onProcess(file)
        else if (mi.id === menus.copyUrl.id) {
            navigator.clipboard.writeText(USE_API_SERVER_URL + file.url)
        }
    }
    return (
        <div key={file.filename} className="itemrow flex flex-row items-center gap-2">
            <Link to={IMG_URL + file.url} target="_blank" className="flex-auto text-ellipsis overflow-hidden"
                onClick={e => e.stopPropagation()}
            >
                {file.filename}
            </Link>
            <ContextMenuButton
                onClick={onMenuHandler}
                onVisible={mi => (mi.id === menus.DELETE && !!onDelete) || (mi.id === menus.PROCESS && !!onProcess) || (mi.id === menus.copyUrl.id && !!allowCopy)}
                menu={ATTACHMENT_FILE_MENU}
            />
        </div>
    )
}

function buildTextFromTemplate(text: string, vars: IRepliesTemplatesVars) {
    // console.log(text, repliesTemplatesVars, vars)
    return text
        .replaceAll(repliesTemplatesVars.contestTitle, vars.contestTitle)
        .replaceAll(repliesTemplatesVars.entityTitle, vars.entityTitle)
        .replaceAll(repliesTemplatesVars.entityUri, vars.entityUri)
        .replaceAll(repliesTemplatesVars.message, vars.message)
}

interface IMessageStateFormProps {
    state: EntityState
    repliesTemplates?: IRepliesTemplates
    vars: IRepliesTemplatesVars
    onSave: (state: EntityState, message: string) => void
    onClose: () => void
}

function MessageStateForm({ state, repliesTemplates, vars, onSave, onClose }: IMessageStateFormProps) {
    const [sendMessage, setSendMessage] = useState<boolean>(true)
    const [message, setMessage] = useState<string>("")
    const [error, setError] = useState<FieldError>()

    function saveHandler() {
        if (state == EntityState.CORRECTION_REQUIRED && message.trim().length == 0) {
            setError({ message: "Опишите требующиеся исправления" } as FieldError)
            return
        }
        onSave(state, message)
    }

    var text = ""
    var subj = ""
    var body = ""
    if (state === EntityState.APPROVED) {
        text = "Изменить статус заявки на одобрена?"
        subj = repliesTemplates?.approvedEntitySubj || defaultRepliesTemplates.approvedEntitySubj || "Заявка одобрена";
        body = repliesTemplates?.approvedEntity || defaultRepliesTemplates.approvedEntity || "Ваша заявка одобрена";
    } else if (state === EntityState.CORRECTION_REQUIRED) {
        text = "Изменить статус заявки на требует коррекции?"
        subj = repliesTemplates?.correctionRequiredEntitySubj || defaultRepliesTemplates.correctionRequiredEntitySubj || "Заявка требует коррекции";
        body = repliesTemplates?.correctionRequiredEntity || defaultRepliesTemplates.correctionRequiredEntity || "Ваша заявка требует коррекции";
    } else if (state === EntityState.REJECTED) {
        text = "Отклонить заявку?"
        subj = repliesTemplates?.rejectedEntitySubj || defaultRepliesTemplates.rejectedEntitySubj || "Заявка отклонена";
        body = repliesTemplates?.rejectedEntity || defaultRepliesTemplates.rejectedEntity || "Ваша заявка отклонена";
    }
    vars.message = message
    subj = buildTextFromTemplate(subj, vars)
    body = buildTextFromTemplate(body, vars)

    return <div className="flex flex-col">
        <div className="mb-8">{text}</div>
        {state != EntityState.CORRECTION_REQUIRED &&
            <Checkbox className="text-sm"
                text="Отправлять сообщение пользователю" left
                value={sendMessage} setValue={setSendMessage} />
        }
        {(sendMessage || state == EntityState.CORRECTION_REQUIRED) &&
            <div className="">
                <LabelWith text="Ваше сообщение" error={error}>
                    <textarea className="textfield w-full"
                        placeholder="Сообщение пользователю"
                        value={message} onChange={e => setMessage(e.target.value)} />
                </LabelWith>
                <div className="mt-4 text-sm">Макет письма пользователю:</div>
                <div className="px-4 pb-4 bg-primary3 rounded">
                    <TextField label="Заголовок письма" text={subj} />
                    <TextField label="Тело письма" text={body} multiline />
                </div>
            </div>}
        <div className="flex flex-row justify-end mt-8 gap-2">
            <button className="greenbutton" onClick={saveHandler}>{strings.button_save}</button>
            <button className="textbutton" onClick={onClose}>{strings.button_cancel}</button>
        </div>
    </div>
}