import { SubmitHandler, useForm } from "react-hook-form"
import { IContestUser, IMessage, ISendMessage, IUserMessage, dbid } from "../../model/models"
import { LabelWith } from "../Label"
import strings from "../../strings"
import { descrValidation, emailValidation, titleValidation } from "./validations"
import { TextField } from "../TextField"
import { IUseModal } from "../../hooks/useModal"
import { Modal } from "../Modal"
import MessageService from "../../services/MessageService"

interface SendMessageFormProps {
    chatId?: dbid
    replyId?: dbid
    to?: IUserMessage | IContestUser
    title?: string
    body?: string
    contestId?: dbid
    onSended: (res: IMessage) => void
    onClose: () => void
}

export function SendMessageForm(props: SendMessageFormProps) {
    const { 
        register,
        formState: { errors },
        handleSubmit 
    } = useForm<ISendMessage>({ defaultValues: {
        chatId: props.chatId, 
        replyId: props.replyId,
        to: props.to?.id,
        title: props.title,
        body: props.body
    } as ISendMessage })

    const onSubmit: SubmitHandler<ISendMessage> = async (data) => {
        try {
            const resp = await MessageService.send(data, props.contestId)
            props.onSended(resp.data);
        } catch(e) {
            console.error(e)
            // let err = e as AxiosError
            // setError(err.message)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-2 w-full'>
            <span className="debugfield">ChatID: {props.chatId}, ReplyID: {props.replyId}</span>
            {props.to 
            ? <TextField text={props.to.name} label={strings.lab_to_user} />
            : <LabelWith text={strings.lab_to_user} error={errors.to}>
                <input className="textinput"
                    placeholder={strings.lab_to_user} 
                    {...register("to", emailValidation(true))}/>
            </LabelWith>
            }

            <LabelWith text={strings.lab_title} error={errors.title}>
                <input className="textinput"
                    placeholder={strings.lab_title} 
                    {...register("title", titleValidation())}/>
            </LabelWith>

            <LabelWith text={strings.lab_body} error={errors.body}>
                <textarea className="textinput" rows={5}
                    placeholder={strings.lab_body} 
                    {...register("body", descrValidation())}/>
            </LabelWith>

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'>{strings.button_send}</button>
                <button type='reset' className='textbutton' onClick={props.onClose}>{strings.button_close}</button>
            </div>
        </form>
    )
}

interface SendMessageDialogProps {
    modal: IUseModal<IUserMessage> | IUseModal<IContestUser>
    chatId?: dbid
    replyId?: dbid
    contestId?: dbid
    // title?: string
    // body?: string
}

export function SendMessageDialog({ modal, chatId, replyId, contestId }: SendMessageDialogProps) {
    if (modal.modal)
        return (
            <Modal title={strings.mi_send_message} onClose={modal.closeModal}>
                <SendMessageForm
                    to={modal.modal}
                    chatId={chatId}
                    replyId={replyId}
                    contestId={contestId}
                    onSended={() => modal.closeModal()}
                    onClose={() => modal.closeModal()}
                    />            
            </Modal>
        )
    else 
        return (
            <></>
        )
}