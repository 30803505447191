import { useTypedSelector } from "../hooks/useTypedSelector";
import { Navigate, Route } from "react-router-dom";
import paths from "../paths";

interface ProtectedRouteProps {
    isAllowed?: boolean
    forJury?: boolean
    forSubmitter?: boolean
    forSiteAdmin?: boolean
    redirectTo?: string
    children: React.ReactElement
}

export default function ProtectedRoute({ isAllowed, forJury, forSubmitter, forSiteAdmin, redirectTo, children }: ProtectedRouteProps) {
    const { isAuth, jury, user, submitter } = useTypedSelector(state => state.auth);
    if (isAllowed === undefined)
        isAllowed = isAuth
    if (forJury && !jury)
        isAllowed = false
    if (forSubmitter && submitter)
        return children
    if (forSiteAdmin && !user?.isSiteAdmin)
        isAllowed = false

    if (isAllowed)
        return (
            // <Route path={path} element={
            children
            // } />
        );
    else
        return <Navigate to={redirectTo || paths.login + "?redirect=" + window.location.pathname} />
}