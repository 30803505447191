import { SubmitHandler, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { WEB_URL } from "../../api";
import { DescrView } from "../../comp/DescrView";
import { LabelWith } from "../../comp/Label";
import { ListUseItems } from "../../comp/ListItems";
import { Modal } from "../../comp/Modal";
import { descrValidation, titleValidation } from "../../comp/forms/validations";
import { useStaticPages } from "../../hooks/useItems";
import { useModal } from "../../hooks/useModal";
import {
  IContest,
  IStaticPage,
  IStaticPageHeader
} from "../../model/models";
import paths from "../../paths";
import StaticPageService from "../../services/StaticPageService";
import strings from "../../strings";

// export const exampleStaticPages = [
//     {
//         id: "test1",
//         title: "Страница регистрации",
//         description: "Форма регистрации в конкурсе",
//     } as IStaticPage
// ]

interface StaticPagesViewProps {
  contest: IContest;
  // onAdd: () => void
}

export function StaticPagesView({ contest }: StaticPagesViewProps) {
  const contestId = contest.id;
  const addStaticPageModal = useModal<IStaticPage>();
  const items = useStaticPages(contestId);

  function savedStaticPage(staticPage: IStaticPageHeader) {
    items.addItem(staticPage);
    addStaticPageModal.closeModal();
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <button
          className="textbutton"
          onClick={() => addStaticPageModal.openModal({} as IStaticPage)}
        >Создать</button>
      </div>
      <ListUseItems
        useItems={items}
        render={(item) => (
          <Link key={item.id}
            className="itemrow flex flex-col"
            to={paths.static_pages_by_id(contestId, item.id)}
          >
            <span className="text-lg">{item.title}</span>
            <DescrView
              className="text-sm line-clamp-2"
              body={item.description}
            />
            <span className="">{WEB_URL}/p/{item.uri}</span>
          </Link>
        )}
      />
      {addStaticPageModal.modal && (
        <Modal
          title={strings.dlg_title_edit_static_page}
          onClose={addStaticPageModal.closeModal}
        >
          <CreateStaticPage
            contest={contest}
            onSave={savedStaticPage}
            onCancel={addStaticPageModal.closeModal}
          />
        </Modal>
      )}
    </div>
  );
}

interface CreateStaticPageProps {
  contest: IContest;
  // page: IStaticPage
  onSave: (page: IStaticPageHeader) => void;
  onCancel: () => void;
}

export function CreateStaticPage({
  contest,
  onSave,
  onCancel,
}: CreateStaticPageProps) {
  // page.contestId = contestId;
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IStaticPage>({
    defaultValues: {
      // id: "",
      contestId: contest.id,
      // fields: contest.template?.fields || [],
      forms: [{ title: "Форма регистрации", fields: contest.template?.fields }],
      isPublic: false,
    },
  });

  const onSubmit: SubmitHandler<IStaticPage> = async (data) => {
    try {
      const resp = await StaticPageService.create(data);
      onSave(resp.data);
    } catch (e) {
      console.error(e);
      // let err = e as AxiosError
      // setError(err.message)
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col space-y-2 w-full"
    >
      <LabelWith text={strings.lab_static_page_title} error={errors.title}>
        <input
          className="textinput"
          placeholder={strings.lab_nomination_title}
          {...register("title", titleValidation())}
        />
      </LabelWith>

      <LabelWith text={strings.lab_static_page_desc} error={errors.description}>
        <textarea
          className="textinput"
          rows={3}
          placeholder={strings.lab_nomination_desc}
          {...register("description", descrValidation())}
        />
      </LabelWith>

      <div className="flex flex-row justify-end gap-2 pt-4">
        <button type="submit" className="greenbutton">
          {strings.button_save}
        </button>
        {onCancel && (
          <button type="reset" className="textbutton" onClick={onCancel}>
            {strings.button_cancel}
          </button>
        )}
      </div>
    </form>
  );
}
