import { IContest, IContestEntity, IContestUser, ILinkToNomination, INomination, IVoteResult, IVoteRules, IVoteSubmit, VoteKind, dbid } from "./models"

export type Operations = ">" | "<" | "=" | ">=" | "<="

export interface IQueryItem {
    field: string
    label: string
    operation?: Operations
    value: string
}

export enum DataNominationSortKind {
    INDEX_ASC = "INDEX_ASC",
    INDEX_DESC = "INDEX_DESC",
    TITLE_ASC = "TITLE_ASC",
    TITLE_DESC = "TITLE_DESC"
}

export enum DataEntitySortKind {
    INDEX_ASC = "INDEX_ASC",
    INDEX_DESC = "INDEX_DESC",
    DATE_ASC = "DATE_ASC",
    DATE_DESC = "DATE_DESC",
    TITLE_ASC = "TITLE_ASC",
    TITLE_DESC = "TITLE_DESC",
    VOTE_ASC = "VOTE_ASC",
    VOTE_DESC = "VOTE_DESC"
}

export interface IDataQuery {
    stageId?: dbid
    nominationIds: dbid[]
    entityQueries: IQueryItem[]
    nominationSort?: DataNominationSortKind
    entitySort?: DataEntitySortKind
}

export interface ITocIDs {
    [index: string]: any | undefined
}

export interface IDataScope {
    [index: string]: any | undefined
}

export interface IDataIDs {
    [index: string]: string | undefined
}

export const TYPE_CONTEST = "contest"
export const TYPE_NOMINATION = "nomination"
export const TYPE_ENTITY = "entity"
export const TYPE_VOTE = "vote"
export const TYPE_HEADER = "header"

function scopeTypedId(type: string, id: dbid) {
    return `${type}(${id})`
}

export const scopes = {
    fromContestId: scopeTypedId.bind(null, TYPE_CONTEST),
    fromNominationId: scopeTypedId.bind(null, TYPE_NOMINATION),
    fromEntityId: scopeTypedId.bind(null, TYPE_ENTITY),
    fromJuryId: scopeTypedId.bind(null, "jury"),
    fromUserId: scopeTypedId.bind(null, "user"),
}

export function fetchFromObject(obj: any, expr: string): any {

    if (typeof obj === 'undefined') {
        return undefined
    }

    const index = expr.indexOf('.')
    if (index >= 0) {
        let prop0 = expr.substring(0, index)
        let prop1 = expr.substring(index + 1)
        if (Array.isArray(obj) && prop0 === "$") {
            return obj.map(o => fetchFromObject(o, prop1) || "").join("\n")
        } else {
            let obj2 = obj[prop0]
            if (!obj2) {
                // console.log(`FetchFromObject ${expr}, unknown prop ${prop0}`)
                return
            }
            return fetchFromObject(obj2, prop1);
        }
    }

    return obj[expr];
}


function checkEntityInQueryItem(queryItem: IQueryItem, entity: any) {
    const m = fetchFromObject(entity, queryItem.field)
    if (m === undefined)
        return false
    if (queryItem.operation) {
        if (typeof m !== "number")
            return false

        let value = Number.parseInt(queryItem.value)
        if (Number.isNaN(value))
            return false

        switch (queryItem.operation) {
            case ">":
                return m > value
            case "<":
                return m < value
            case "=":
                // console.log(`Compare ${m} === ${value}`)
                return m === value
            case ">=":
                return m >= value
            case "<=":
                return m <= value
        }
    } else
        return m === queryItem.value
}

// TODO make private
export function checkEntityInQuery(query: IDataQuery, item: ITocItem) {
    if (query.entityQueries.length === 0)
        return true
    for (let index = 0; index < query.entityQueries.length; index++) {
        const queryItem = query.entityQueries[index]
        if (queryItem.field.startsWith("$")) {
            if (!checkEntityInQueryItem(queryItem, item))
                return false
        } else {
            if (!checkEntityInQueryItem(queryItem, item.entity))
                return false
        }
    }
    return true
}

interface IJuryVote {
    name: string
    value?: number
}

export interface IJuryVoteResult {
    [id: string]: IJuryVote | undefined
}

export interface IVoteData {
    stageId: dbid
    jury: IContestUser[]
    result: IVoteResult[]
}

export class ITocItem {
    id: dbid
    scopeId: string
    title: string
    stages: string[] | undefined

    $votesValue: number | undefined = undefined
    $votesPoints: number | undefined = undefined
    $votesCount: number | undefined = undefined
    $voteResult: IJuryVoteResult | undefined = undefined

    constructor(public entity: IContestEntity, public index: number, nominationId: dbid) {
        this.id = entity.id
        this.title = entity.title
        this.scopeId = scopes.fromEntityId(entity.id)
        this.stages = entity.nominations && entity.nominations[nominationId]?.stages
    }
}

export class TocSection {
    items = new Array<ITocItem>()
    loaded = false
    empty = false
    nominationScope: string

    constructor(public nomination: INomination) {
        this.nominationScope = scopes.fromNominationId(nomination.id)
    }

    append(entities: IContestEntity[]) {
        this.items = [...this.items, ...entities.map((e, index) => new ITocItem(e, this.items.length + index, this.nomination.id))]
        this.loaded = true
        this.empty = this.items.length === 0
    }

    setVoteResult(voteRules: IVoteRules | undefined, voteData: IVoteData) {
        this.items.forEach(item => {
            let votesRes = calcVotes(voteData.jury, voteData.result, this.nominationScope, item.scopeId, voteRules)
            item.$votesValue = votesRes.value
            item.$votesPoints = votesRes.points
            item.$votesCount = votesRes.count

            const votes = {} as IJuryVoteResult
            voteData.jury.forEach(jury => {
                let juryScopeId = scopes.fromJuryId(jury.id)
                votes[juryScopeId] = { name: jury.name, value: votesRes.values[juryScopeId] }
            })
            item.$voteResult = votes
        })
    }

    clearVoteResult() {
        this.items.forEach(item => {
            item.$votesValue = undefined
            item.$votesPoints = undefined
            item.$votesCount = undefined
        })
    }

    filterQuery(query: IDataQuery) {
        let childs = this.items.filter(item => {
            if (!checkEntityInQuery(query, item))
                return false
            if (query.stageId) {
                if (!item.stages || item.stages.indexOf(query.stageId) < 0)
                    return false
            }
            return true
        })
        return childs.sort((a, b) => sortDocEntities(a, b, query.entitySort))
    }
}

export class TocData {
    contest: IContest
    sections: TocSection[] = []
    ids: ITocIDs

    constructor(contest: IContest, sections: TocSection[] = []) {
        this.contest = contest
        this.ids = {}
        sections.forEach(sect => this.push(sect))
        this.ids[scopes.fromContestId(contest.id)] = contest
    }

    push(sect: TocSection) {
        if (this.sections.indexOf(sect) < 0)
            this.sections.push(sect)
        this.updateIndex(sect)
    }

    updateIndex(sect: TocSection) {
        this.ids[scopes.fromNominationId(sect.nomination.id)] = sect.nomination
        sect.items.forEach(item =>
            this.ids[item.scopeId] = item.entity
        )
    }

    getById(id: string) {
        return this.ids[id]
    }

    fetchObj(prop: string, scope: IDataScope | undefined, depends: IDataIDs | undefined) {
        let res = this.getById(prop)
        if (res)
            return res
        let value = scope && scope[prop]
        if (typeof value !== "undefined")
            return value

        let dependValue = depends && depends[prop]

        if (typeof dependValue === "string") {
            return this.getById(dependValue)
        } else
            return dependValue
    }

    fetchExprFromDepends(expr: string, depends: IDataIDs | undefined) {
        const n = expr.indexOf('.')
        if (n < 0) {
            let res = this.getById(expr) || (depends && depends[expr])
            // !res && console.log(`Broken template expression: ${expr}`)
            return res
        }
        let obj = this.fetchObj(expr.substring(0, n), undefined, depends)
        if (!obj) {
            // console.log(`Broken template expression: ${expr}, unknown object`, depends)
            return
        }
        let prop = expr.substring(n + 1)
        let res = fetchFromObject(obj, prop)
        // !res && console.log(`Broken template expression: ${expr}, unknown prop ${prop}`, obj)
        return res
    }

    fetchExprFromScope(expr: string, scope: IDataScope) {
        const n = expr.indexOf('.')
        if (n < 0) {
            let res = this.getById(expr) || scope[expr]
            // !res && console.log(`Broken template expression: ${expr}`)
            return res
        }
        let obj = this.fetchObj(expr.substring(0, n), scope, undefined)
        if (!obj) {
            // console.log(`Broken template expression: ${expr}, unknown object`, scope, undefined)
            return
        }
        let prop = expr.substring(n + 1)
        let res = fetchFromObject(obj, prop)
        // !res && console.log(`Broken template expression: ${expr}, unknown prop ${prop}`, obj)
        return res
    }

    fetchExpr(expr: string, scope: IDataScope, depends: IDataIDs | undefined) {
        return this.fetchExprFromScope(expr, scope) || this.fetchExprFromDepends(expr, depends)
    }

    filterQuery(query: IDataQuery) {
        return (query.nominationIds.length > 0
            ? this.sections.filter(sect => query.nominationIds.indexOf(sect.nomination.id) >= 0)
            : this.sections
        ).sort((a, b) => sortDocNominations(a.nomination, b.nomination, query.nominationSort))
    }

    setVoteResult(voteData: IVoteData) {
        let voteRules = this.voteRules(voteData.stageId)
        this.sections.forEach(sect => sect.setVoteResult(voteRules, voteData))
    }

    clearVoteResult() {
        this.sections.forEach(sect => sect.clearVoteResult())
    }

    voteRules(stageId: dbid | undefined) {
        return stageId ? this.contest.stages.find(stg => stg.id === stageId)?.voting : undefined
    }
}

export function sortDocNominations(a: INomination, b: INomination, kind?: DataNominationSortKind): number {
    switch (kind || DataNominationSortKind.INDEX_ASC) {
        case DataNominationSortKind.INDEX_ASC:
            return a.index - b.index
        case DataNominationSortKind.INDEX_DESC:
            return b.index - a.index
        case DataNominationSortKind.TITLE_ASC:
            return a.title.localeCompare(b.title)
        case DataNominationSortKind.TITLE_DESC:
            return b.title.localeCompare(a.title)
    }
}

export function sortDocEntities(a: ITocItem, b: ITocItem, kind?: DataEntitySortKind): number {
    switch (kind || DataEntitySortKind.INDEX_ASC) {
        case DataEntitySortKind.INDEX_ASC:
            return a.index - b.index
        case DataEntitySortKind.INDEX_DESC:
            return b.index - a.index
        case DataEntitySortKind.TITLE_ASC:
            return a.title.localeCompare(b.title)
        case DataEntitySortKind.TITLE_DESC:
            return b.title.localeCompare(a.title)
        case DataEntitySortKind.DATE_ASC:
            return (a.entity.created || 0) - (b.entity.created || 0)
        case DataEntitySortKind.DATE_DESC:
            return (b.entity.created || 0) - (a.entity.created || 0)
        case DataEntitySortKind.VOTE_ASC:
            return (a.$votesValue || 0) - (b.$votesValue || 0)//return (a.$votesPoints || 0) - (b.$votesPoints || 0)
        case DataEntitySortKind.VOTE_DESC:
            return (b.$votesValue || 0) - (a.$votesValue || 0)//return (b.$votesPoints || 0) - (a.$votesPoints || 0)
    }
}

export interface ICalcVotesResult {
    value: number
    valueRaw: number
    points: number
    pointsRaw: number;
    count: number
    names: Array<string>
    values: { [juryScope: string]: number | undefined }
}

interface JuryCoefs {
    [index: string]: number | undefined
};

export function calcVotes(juries: IContestUser[] | undefined, votes: IVoteResult[], nominationScope: string, entity: string, rules: IVoteRules | undefined) {
    // console.log("calcVotes: " + rules?.voteKind)
    let max = rules?.numbers || 3
    let res: ICalcVotesResult = { value: 0, valueRaw: 0, points: 0, pointsRaw: 0, count: 0, names: [], values: {} }
    let syms = (max + "").length
    let juryCoefs: JuryCoefs = {};
    juries?.forEach(function (j) {
        let coeffS = j.options?.voteCoeff;
        if (coeffS) {
            let coeff = Number.parseFloat(coeffS);
            if (coeff && !Number.isNaN(coeff)) {
                juryCoefs[scopes.fromJuryId(j.id)] = coeff;
            }
        }
    });
    // console.log(juryCoefs);

    votes.forEach(vote => {
        let coeff = juryCoefs[vote.scopeId] || 1;
        let item = vote.items[nominationScope]
        let value = item && item[entity]
        if (!value) {
            return;
        }
        res.count++;

        switch (rules?.voteKind) {
            case VoteKind.PLACES:
                if (!value)
                    value = 0
                res.pointsRaw += max - (value - 1);
                res.points = res.pointsRaw * coeff;
                res.value += value * coeff
                res.valueRaw += value
                if (item && value) {
                    let valueS = (value + "").padStart(syms, " ")
                    res.values[vote.scopeId] = value
                    res.names.push(`${valueS}-${vote.name}`)
                }
                break;
            case VoteKind.POINTS:
                if (!value)
                    value = 0
                res.pointsRaw += value;
                res.points += value * coeff;
                res.value = res.points / res.count;
                res.valueRaw = res.pointsRaw / res.count;
                // console.log(`Calc POINTS`, JSON.stringify(res));
                if (item && value) {
                    let valueS = (value + "").padStart(syms, " ")
                    res.values[vote.scopeId] = value
                    res.names.push(`${valueS}-${vote.name}`)
                }
                break
            case undefined:
            case VoteKind.SINGLE:
            case VoteKind.MANY:
                if (item && value) {
                    let valueS = (value + "").padStart(syms, " ")
                    res.values[vote.scopeId] = value
                    res.names.push(`${valueS}-${vote.name}`)

                    res.value += value
                    res.valueRaw += value
                    res.points += value
                    res.pointsRaw += value
                }
                break;
        }

    })
    if (rules?.voteKind === VoteKind.PLACES)
        res.names.sort((a, b) => a.localeCompare(b))
    return res
}
