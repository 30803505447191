import { useState } from "react"
import { IPoint } from "../model/common"
import { ContextMenu, ContextMenuDir, IContextMenuItem, getContextMenuPtFromEvent } from "./ContextMenu"
import { IconButton } from "./Icon"

interface ContextMenuButtonProps {
    className?: string
    color?: string
    background?: string
    icon?: string
    size?: string | number
    iconSize?: string | number
    menu?: IContextMenuItem[]
    dir?: ContextMenuDir
    onClick: (id: IContextMenuItem) => void
    onDisabled?: (item: IContextMenuItem) => boolean
    // onEnabled?: (item: IContextMenuItem) => boolean
    onVisible?: (item: IContextMenuItem) => boolean
}

export default function ContextMenuButton(props: ContextMenuButtonProps) {
    const [show, setShow] = useState(false)
    const visibility = props.onVisible === undefined
        || (props.menu && props.menu.filter(mi => props.onVisible && props.onVisible(mi)).length > 0)
    function clickHandler(e: React.MouseEvent) {
        setShow(!show)
        // setShowPt(getContextMenuPtFromEvent(e))
    }
    if (visibility)
        return (
            <div className="relative">
                <IconButton
                    className={props.className || ""}
                    name={props.icon || "more_vert"}
                    size={props.size}
                    iconSize={props.iconSize}
                    onClick={clickHandler} />
                {props.menu && show &&
                    <ContextMenu
                        color={props.color}
                        background={props.background}
                        menu={props.menu}
                        dir={props.dir || ContextMenuDir.FromRightBottom}
                        onClick={props.onClick}
                        onClose={() => setShow(false)}
                        onDisabled={props.onDisabled}
                        onVisible={props.onVisible} />
                }
            </div>
        )
    else
        return (
            <></>
        )
}