import React, { useEffect, useState } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { Label } from './Label';
import strings from '../strings';
import { Icon } from './Icon';

const emailValidation = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum TextInputType {
    text,
    multiline,
    email, 
    password
}

interface ValidationsProps {
    // email?: boolean
    minLength?: number
    maxLength?: number
    regexp?: RegExp
}

export const useValidation = (name: string, value: string, validations?: ValidationsProps) => {
    const [error, setError] = useState("");
    useEffect(() => {
        var errors: string[] = []
        // if (isEmail && !emailValidation.test(value)) {
        //     errors.push("Неправильно введен email")
        // }
        if (validations?.minLength && value.length < validations.minLength) {
            errors.push(`${strings.msg_invalid_length_min} ${validations.minLength}`)
        }
        if (validations?.maxLength && value.length > validations.maxLength) {
            errors.push(`${strings.msg_invalid_length_max} ${validations.maxLength}`)
        }
        if (validations?.regexp && !validations.regexp.test(value)) {
            errors.push(`${strings.msg_invalid_input} ${name}`)
        }
        setError(errors.join(", "))
    }, [name, value])
    return {
        error
    }
}

type InputElement = HTMLInputElement | HTMLTextAreaElement

export function useInput(
    name: string, 
    value: string, 
    validations?: ValidationsProps
) {
    // const [value, setValue] = useState(initValue)
    const [isDirty, setDirty] = useState(false)
    const { error } = useValidation(name, value, validations)

    // const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // setValue(e.target.value)
    // }
    const onBlur = (e: React.FocusEvent<InputElement, Element>) => {
        setDirty(true)
    }
    return {
        isDirty, onBlur, error 
    }
}

interface InputProps {
    type: TextInputType
    placeholder: string
    label?: string | null
    name?: string
    disabled?: boolean
    className?: string
    value: string
    // setValue: React.Dispatch<React.SetStateAction<string>>
    setValue: (next: string) => void
    onChange?: (s: string) => void
    validations?: ValidationsProps
}

export function TextInput(props: InputProps) {
    var type = 'text'
    var validations = props.validations
    switch (props.type) {
        case TextInputType.email:
            validations = validations || { regexp: emailValidation }
            break
        case TextInputType.password:
            type = 'password'
            validations = validations || { minLength: 3, maxLength: 32 }
            break
    }
    const state = useInput(
        props.name || props.placeholder, 
        props.value, 
        validations
    )

    function changeHandler(e:  React.ChangeEvent<InputElement>) {
        props.setValue(e.target.value)
        props.onChange && props.onChange(e.target.value)
    }

    return (
        <div className={props.className}>
        { (state.isDirty && state.error) && <ErrorMessage message={state.error}/>}
        
        {props.label !== null && 
        <Label text={props.label || props.placeholder} disabled={props.disabled}/>
        }
        <div className="relative ">
        { props.type === TextInputType.multiline 
        ? <textarea 
            placeholder={props.placeholder}
            disabled={props.disabled}
            className={"textinput"}
            value={props.value} 
            onChange={changeHandler}
            onBlur={e => state.onBlur}
            />
        : <input 
            type={type}
            disabled={props.disabled}
            placeholder={props.placeholder}
            className={"textinput"}
            value={props.value} 
            onChange={changeHandler}
            onBlur={state.onBlur}
            />
        }
        <button className='absolute right-1 inset-y-0 flex justify-center items-center' onClick={() => props.setValue("")}>
            <Icon name='close'/>
        </button>
        </div>

        </div>
    )
}