import { SubmitHandler, useForm } from "react-hook-form"
import { LabelWith } from "../../comp/Label"
import { emailValidation, passwordValidation } from "../../comp/forms/validations"
import strings from "../../strings"
import { dbid } from "../../model/models"
import { useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { TextField } from "../../comp/TextField"
import { useAppDispatch } from "../../hooks/useTypedSelector"
import { juryLoginAction } from "../../store/actions/auth"
import JuryService, { IJuryData } from "../../services/JuryService"

export interface IJuryLoginForm {
    contestId: dbid
    contestName?: string 
    username: string
    password: string
}

interface JuryLoginTabProps {
    title: string
    // onSubmit: SubmitHandler<IJuryLoginForm>
}

export function JuryLoginTab(props: JuryLoginTabProps) {
    const dispatch = useAppDispatch()
    const [jury, setJury] = useState<IJuryData>()
    const [ params ] = useSearchParams()
    const juryData = params.get("jury")
    useEffect(() => {
        if (juryData) {
            const o = JuryService.decodeObject(juryData)
            if (o && o.contestId && o.contestName && o.username && o.fullname) {
                setJury(o)
            }
        }
    }, [juryData])

    const onSubmit: SubmitHandler<IJuryLoginForm> = async (data) => {
        dispatch(juryLoginAction(
            jury?.contestId || data.contestId, 
            jury?.username || data.username, 
            jury?.password || data.password
        ))
    }

    const { register, formState: { errors }, handleSubmit } = useForm<IJuryLoginForm>({ defaultValues: {} as IJuryLoginForm })
    return (
        <form title={props.title} className='flex flex-col space-y-2 mt-2 w-full'
            onSubmit={handleSubmit(onSubmit)}>
            { jury 
            ? <>
            <div className="text-center pt-4">
                <span>{strings.msg_login_jury} <b>"{jury.contestName}"</b></span>
            </div>
            <TextField label={strings.lab_jury} text={jury.fullname} className="pb-4"/>
            </>
            : <>
            <LabelWith text={strings.lab_contest} error={errors.contestId}>
                <select className="combobox"></select>
            </LabelWith>

            <LabelWith text={strings.lab_email} error={errors.username}>
                <input className='textinput' 
                    placeholder={strings.lab_email}
                    {...register("username", emailValidation(true))} />
            </LabelWith>
            </>
            }
            
            <LabelWith text={strings.lab_password} error={errors.password}>
                { jury?.password 
                ? <input className='textinput' type="password" value={jury.password} readOnly/>                
                : <input className='textinput' type="password"
                    placeholder={strings.lab_password}
                    {...register("password", passwordValidation())} />
                }
            </LabelWith>

            <button type='submit'className='textbutton'>{strings.button_login}</button>
        </form>
    )
}