import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import api from '../api';
import { IContest, IContestEntity, IContestExample, IContestUser, IDocument, IJob, IMessage, INomination, IPhoto, IStaticForm, IStaticPage, IStaticPageHeader, IUser, IUserVoteResult, IVoteResult, dbid } from '../model/models';
import DataService from '../services/DataService';
import { ISlide } from '../model/pptx';
import { IDocumentHeader } from '../model/xlsx';
import StaticPageService from '../services/StaticPageService';

const DEF_LIMIT = 50
type callback<T> = (err: string | null, data: T[] | null) => void

export interface UseItems<T> {
    items: T[]
    loading: boolean
    error: string
    canMore: boolean
    addItem: (item: T, begin?: boolean) => void
    removeItem: (item: T) => void
    replaceItem: (src: T, dst: T) => void
    more: () => void
    reload: (res?: callback<T>) => void
}

export function useItems<T>(apiUri: string, limit = DEF_LIMIT, loadAll: boolean = false): UseItems<T> {
    const [items, setItems] = useState<T[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [canMore, setCanMore] = useState(false)

    function addItem(item: T, begin?: boolean) {
        if (begin)
            setItems(prev => [item, ...prev])
        else
            setItems(prev => [...prev, item])
    }
    function removeItem(item: T) {
        setItems(prev => [...prev.filter(value => value !== item)]);
    }
    function replaceItem(src: T, dst: T) {
        let n = items.indexOf(src)
        if (n < 0) {
            console.error("Can't replace item ", src, dst)
            return
        }
        items[n] = dst
        setItems([...items])
    }
    async function loadNext(offset: number) {
        // const offset = items.length
        var uri: string
        if (apiUri.indexOf('?') > 0)
            uri = `${apiUri}&offset=${offset}&limit=${limit}`
        else
            uri = `${apiUri}?offset=${offset}&limit=${limit}`

        try {
            const resp = await api.get<T[]>(uri)
            const nextItems = items.concat(resp.data)
            let canMore = resp.data.length === limit
            setItems(nextItems)
            setCanMore(canMore)
            return { canMore, offset: nextItems.length }
        } catch (e: unknown) {
            const err = e as AxiosError
            setError(err.message)
            return
        }
    }
    async function more() {
        setLoading(true);
        const offset = items.length
        loadNext(offset)
        setLoading(false);
    }
    async function reload(res?: callback<T>) {
        var uri: string
        if (apiUri.indexOf('?') > 0)
            uri = `${apiUri}&limit=${limit}`
        else
            uri = `${apiUri}?limit=${limit}`
        try {
            setError("");
            setLoading(true);
            const resp = await api.get<T[]>(uri)
            setItems(resp.data)
            let canMore = resp.data.length === limit
            setCanMore(canMore)
            res && res(null, resp.data)
            let offset = resp.data.length
            while (loadAll && canMore && !error) {
                let resp1 = await loadNext(offset)
                canMore = resp1?.canMore === true
                offset = resp1?.offset || offset
            }
        } catch (e: unknown) {
            const err = e as AxiosError
            setError(err.message)
            res && res(err.message || "Error", null)
        }
        setLoading(false);
    }

    useEffect(() => {
        reload()
    }, [apiUri]);

    return { items, loading, error, canMore, addItem, removeItem, replaceItem, reload, more } as UseItems<T>
}

interface ILoader<T> {
    // offset: number
    canMore: boolean
    items: T[] | null
    error?: string
}

export function useItemsLoadAll<T>(apiUri: string, limit = DEF_LIMIT) {
    const [items, setItems] = useState<T[]>(() => []);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>();
    async function loadNext(offset: number) {
        var uri: string
        if (apiUri.indexOf('?') > 0)
            uri = `${apiUri}&offset=${offset}&limit=${limit}`
        else
            uri = `${apiUri}?offset=${offset}&limit=${limit}`

        try {
            const resp = await api.get<T[]>(uri)
            let canMore = resp.data.length === limit
            return { canMore, items: resp.data } as ILoader<T>
        } catch (e: unknown) {
            const err = e as AxiosError
            setError(err.message)
            return { canMore: false, error: err.message } as ILoader<T>
        }
    }
    async function reload() {
        // console.log("Reload", apiUri)
        setError(undefined);
        setLoading(true);
        var nextItems: T[] = []
        var o = { offset: 0, canMore: true, items: [] } as ILoader<T>
        while (o && o.canMore) {
            o = await loadNext(nextItems.length)
            // console.log("loadNext" + nextItems.length, o)
            if (o && o.items) {
                nextItems = [...nextItems, ...o.items]
            }
        }
        // console.log("nextItems", nextItems)
        setItems(nextItems)
        setLoading(false);
    }

    useEffect(() => {
        // console.log(`useItemsLoadAll.useEffect \"${apiUri}\"`, )
        reload()
    }, [apiUri]);

    return { items, setItems, loading, reload, error }
}

export function usePublicContests() {
    return useItems<IContest>("/contests/public")
}

export function useOpenContests() {
    return useItems<IContestExample>("/contests/open")
}

export function useContests() {
    return useItems<IContest>("/contests")
}

export function useContestExamples() {
    return useItems<IContestExample>("/examples")
}

export enum NominationsSortDir {
    TITLE_ASC = "TITLE_ASC",
    CREATE_ASC = "CREATE_ASC",
    CREATE_DESC = "CREATE_DESC",
    INDEX_ASC = "INDEX_ASC"
}

export function useNominations(contestId: dbid, sort: NominationsSortDir) {
    return useItems<INomination>(`/nominations/${contestId}?sort=${sort}`)
}

export function useNominationsAll(contestId: dbid) {
    return useItemsLoadAll<INomination>(`/nominations/${contestId}`)
}

export function useNominationsFor(contestId: dbid, entityId: dbid) {
    return useItems<INomination>(`/participations/${contestId}/contestant/${entityId}`)
}

export enum EntitySortDir {
    CREATE_ASC = "CREATE_ASC",
    CREATE_DESC = "CREATE_DESC",
}

export function useContestEntities(contestId: dbid, sort: EntitySortDir) {
    return useItems<IContestEntity>(`/contestants/${contestId}?sort=${sort}`)
}

export function useContestEntitiesAll(contestId: dbid, sort: EntitySortDir) {
    return useItemsLoadAll<IContestEntity>(`/contestants/${contestId}?sort=${sort}`)
}

export function usePhotos() {
    return useItems<IPhoto>("/photos")
}

export function useJobs(contestId: dbid) {
    return useItems<IJob>(`/jobs/${contestId}`)
}

export function useDocuments(contestId: dbid) {
    return useItems<IDocument>(`/documents/${contestId}`)
}

export function useStaticPages(contestId: dbid) {
    return useItems<IStaticPageHeader>(`/pages/list/${contestId}`)
}

export function useMessages(sent: boolean) {
    // return useItems<IDocument>(`/documents/${contestId}`)
    return useItems<IMessage>(`/messages/${sent ? "sent" : "inbox"}`)
}

export function useChatMessages(chatId: string) {
    return useItems<IMessage>(`/messages/chat/${chatId}`)
}

export function useVoteResults(contestId: dbid) {
    return useItems<IUserVoteResult>(`/votes/${contestId}`)
}

export function useVoteResultsBy(contestId: dbid, stageId: dbid, name: string) {
    return useItems<IVoteResult>(`/votes/${contestId}/by?stage=${stageId}&name=${name}`, 50, true)
}

export function usePresentations(contestId: dbid) {
    return useItems<IDocumentHeader>(`/presentations/list/${contestId}`)
}

export function useTables(contestId: dbid) {
    return useItems<IDocumentHeader>(`/tables/list/${contestId}`)
}

export function useSlideTemplates(contestId: dbid) {
    return useItems<ISlide>(`/slide_templates/list/${contestId}`)
}

export enum UserKind {
    ADMINS, USERS, JURY
}

export function useContestUsers(contestId: dbid, kind: UserKind) {
    var path: string// = admins ? "admins" : "participate"
    switch (kind) {
        case UserKind.ADMINS:
            path = "owners"
            break
        case UserKind.USERS:
            path = "participants"
            break
        case UserKind.JURY:
            path = "jury"
            break
    }
    return useItems<IContestUser>(`/contests/${contestId}/${path}`)
}

export function useSiteUsers() {
    return useItems<IUser>(`/admin/users`)
}

export function useSiteContests() {
    return useItems<IContest>(`/admin/contests`)
}

export function useContest(contestId: dbid) {
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [contest, setContest] = useState<IContest | null>(null)
    async function fetchData() {
        setLoading(true)
        try {
            const resp1 = await DataService.getContest(contestId)
            setContest(resp1.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [contestId])
    return { contest, setContest, loading, error }
}

export function useContestEntity(contestId: dbid, entityId: dbid) {
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [contestEntity, setContestEntity] = useState<IContestEntity | null>(null)
    async function fetchData() {
        setLoading(true)
        try {
            const resp = await DataService.getContestEntity(contestId, entityId)
            setContestEntity(resp.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [contestId, entityId])
    return { contestEntity, setContestEntity, loading, error, reload: fetchData }
}

export function useStaticPageForm(pageId: dbid | undefined, nominationIds: string[] | undefined, onlyPublic: boolean) {
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [staticForm, setStaticForm] = useState<IStaticForm>();
    const { staticPage } = useStaticPage(pageId, onlyPublic)
    async function fetchData() {
        // console.log("fetchData staticPage", staticPage, nominationIds, onlyPublic)
        if (!staticPage || !nominationIds || nominationIds.length == 0) {
            return
        }
        const next = staticPage.forms?.find(form => {
            for (var i = 0; i < nominationIds.length; i++) {
                const nomId = nominationIds[i];
                // console.log("Check nom", nomId, form.nominations?.map(e => e.id))
                if (!form.nominations?.find(nom => nom.id == nomId))
                    return false
            }
            return true
        })
        setStaticForm(next)
    }
    useEffect(() => {
        fetchData()
    }, [staticPage, nominationIds])
    return { staticForm, setStaticForm, loading, error }
}

export function useStaticPage(pageId: dbid | undefined, onlyPublic: boolean) {
    const [error, setError] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [staticPage, setStaticPage] = useState<IStaticPage | null>(null);

    async function fetchData() {
        if (!pageId)
            return
        setLoading(true)
        try {
            const resp1 = onlyPublic
                ? await StaticPageService.getPublicStaticPage(pageId)
                : await StaticPageService.getStaticPage(pageId);

            setStaticPage(resp1.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [pageId])
    return { staticPage, setStaticPage, loading, error }
}