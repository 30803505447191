import { EntityState, IContestExample } from "../../model/models"
import { useState } from "react"
import { ContextMenu, IContextMenuItem, getContextMenuPtFromEvent } from "../ContextMenu"
import strings from "../../strings"
import { IPoint } from "../../model/common"

const EXAMPLE_ENTITY_STATE_MENU = [
    { id: EntityState.NOT_SUBMITTED, title: strings.all_entity_state_not_submitted } as IContextMenuItem,
    { id: EntityState.APPROVED, title: strings.all_entity_state_approved } as IContextMenuItem
] 

interface ExampleContestRowProps {
    item: IContestExample
    onSubmit: (item: IContestExample, entityState: EntityState) => void
}

export function ExampleContestRow({ item, onSubmit }: ExampleContestRowProps) {
    const [showPt, setShowPt] = useState<IPoint>()

    function clickHandler(e: React.MouseEvent) {
        let pt = getContextMenuPtFromEvent(e)
        setShowPt(pt)
    }
    function entityClickHandler(mi: IContextMenuItem) {
        const state = Object.values(EntityState).find( state => state === mi.id)
        if (state) 
            onSubmit(item, state)
    }
    return (
        <div className='itemrow flex flex-col'
            onClick={clickHandler}>
            <div>{item.title}</div>
            <div className='line-clamp-3 text-sm text-disabled'>{item.description}</div>
            {showPt && 
            <ContextMenu menu={EXAMPLE_ENTITY_STATE_MENU} pt={showPt}
                onClick={entityClickHandler} 
                onClose={() => setShowPt(undefined)}/>
            }            
        </div>
    )
}