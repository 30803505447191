import { useNavigate } from "react-router-dom"
import { IMessage } from "../../model/models"
import paths from "../../paths"

interface MessageRowProps {
    item: IMessage
}

export function MessageRow({ item }: MessageRowProps) {
    const navigate = useNavigate()
    function clickHandler() {
        navigate(`${paths.messages}/${item.id}`)
    }
    return (
        <div className="itemrow flex flex-row" onClick={clickHandler}>
            <div className={ "basis-1/3 " + (item.isRead ? "" : "font-bold")}>{item.from.name}</div>
            <div className="basis-2/3 flex flex-col">
                <span className={"line-clamp-2 " + (item.isRead ? "" : "font-bold")}>{item.title}</span>
                {/* <span>{item.isRead ? "прочитано" : "не прочитано"}</span> */}
                <span className="italic text-disabled">{new Date(item.date).toLocaleString()}</span>
            </div>
        </div>
    )
}