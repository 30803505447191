import { IVoteSubmit } from "../../../../model/models";
import { ISlideVoteField } from "../../../../model/pptx";
import { EmptyView } from "../../../../comp/Loading";
import { VotingPoints } from "../../../../comp/VotingPoints";
import { OnGotoSlideById } from "../SlideVoteBox";
import { preventClickPropagation } from "./VotePlacesView";

interface IVotePointsViewProps {
    data: ISlideVoteField
    votes: IVoteSubmit
    setVotes: (next: IVoteSubmit) => void
    onGoto?: OnGotoSlideById
    onSubmit?: (value: IVoteSubmit) => void
}

export function VotePointsView({ data, votes, setVotes, onGoto, onSubmit }: IVotePointsViewProps) {
    return (
        <div className="w-full h-full flex flex-col overflow-hidden px-2 py-2 rounded-[10px] "
            onClick={preventClickPropagation}
            style={{
                // background: "rgba(0, 0, 0, 0.2)",
                // backdropFilter: "blur(10px)",
                // backdropFilter: "invert(80%)", 
                // backgroundColor: "rgba(255, 255, 255, .2)",
                // backgroundColor: "rgba(142, 154, 175, .5)",// primary: "#8E9AAF"
            }}>
            <div className={"flex-auto flex flex-col overflow-y-auto bg-error0  text-white " + (data.verticalAlign ? "justify-" + data.verticalAlign : "")}
                style={{
                    // backdropFilter: "blur(10px)", 
                    // backgroundColor: "rgba(255, 255, 255, .2)",
                    // backgroundColor: "rgba(142, 154, 175, .5)",// primary: "#8E9AAF"
                }}>
                {data.items.length === 0
                    ? <EmptyView />
                    : <div className={"flex flex-col " + (data.align ? "items-" + data.align : "")}>
                        {data.items.map(item =>
                            <div key={item.id}
                                className="flex-none flex flex-row items-center gap-x-2 overflow-hidden">
                                <VotingPoints className="flex-none" textClassName="hidden"
                                    color={data.outside ? "white" : "black"}
                                    value={votes[item.id] || 0}
                                    onChange={e => {
                                        votes[item.id] = e;
                                        let next = { ...votes }
                                        setVotes(next);
                                        onSubmit && onSubmit(next);
                                    }} />
                                {!data.hideNames &&
                                    <span className="flex-auto text-ellipsis line-clamp-1"
                                        style={{ color: data.outside ? "white" : "black" }}>
                                        {item.title}
                                    </span>
                                }
                            </div>
                        )}
                    </div>}
            </div>
            {/* <div className="flex-none flex flex-row justify-center">
                <button disabled={!onSubmit}
                    className="greenbutton mt-2 min-w-1/2"
                    onClick={() => onSubmit && onSubmit(votes)}>{strings.button_vote}</button>
            </div> */}
        </div>
    );
}
