import strings from "../strings"
import { Icon } from "./Icon"
import { EmptyView } from "./Loading"

interface DescrViewProps {
    body?: string
    className?: string
    placeholder?: string
    buttonIcon?: string
    buttonOnClick?: () => void
}

export function DescrView({ body, className, placeholder, buttonIcon, buttonOnClick }: DescrViewProps) {
    const useClassName = "description relative " + (className || "")
    if (body)
        return (
            <div className={useClassName}>
                <div dangerouslySetInnerHTML={{ __html: body.replaceAll("\n", "<br/>") }}></div>
                {buttonIcon &&
                    <div className={"absolute right-[4px] bottom-[2px] flex flex-row justify-center items-center py-2 " + (buttonOnClick ? "cursor-pointer" : "")}
                        onClick={buttonOnClick}>
                        <Icon name={buttonIcon} className="bg-transparent border-0" />
                    </div>
                }
            </div>
        )
    else
        return (
            <div className="relative">
                <EmptyView nopadding={true}
                    className={useClassName}
                    text={placeholder || (strings.msg_missing + " " + strings.lab_descr.toLowerCase())}
                    align="start" />
                {buttonIcon &&
                    <div className={"absolute right-[4px] bottom-[2px] flex flex-row justify-center items-center py-2 " + (buttonOnClick ? "cursor-pointer" : "")}
                        onClick={buttonOnClick}>
                        <Icon name={buttonIcon} className="bg-transparent border-0" />
                    </div>
                }

            </div>
        )
}