import { Checkbox } from "../../../comp/Checkbox";
import { Label } from "../../../comp/Label";
import { AlignTextField, VerticalAlignField } from "../../../model/common";
import { DependsDataContext } from "../../../model/depends";
import { ISlideVoteField } from "../../../model/pptx";
import { HorzAlignment, VertAlignment } from "./EditTextFieldTab";


interface EditVoteTabProps {
    data: ISlideVoteField
    isTemplate: boolean
    context?: DependsDataContext
    onChange: (nextData: ISlideVoteField) => void
    onClose: () => void
}

export function EditVoteTab({ data, isTemplate, context, onChange, onClose }: EditVoteTabProps) {
    // const items = data.items
    return (
        <div className="flex flex-col gap-2">
            <Checkbox left text="Вне слайда" value={data.outside || false} setValue={e => onChange({ ...data, outside: e })} />
            <Checkbox left text="Скрывать имена" value={data.hideNames || false} setValue={e => onChange({ ...data, hideNames: e })} />
            <Label text="Выравнивание текста" />
            <div className="flex flex-row flex-wrap justify-around">
                <HorzAlignment text="По горизонтали" value={data.align || AlignTextField.left} setValue={e => onChange({ ...data, align: e })} />

                <VertAlignment text="По вертикали" value={data.verticalAlign || VerticalAlignField.start} setValue={e => onChange({ ...data, verticalAlign: e })} />
            </div>
        </div>
    );
}
