import ContextMenuButton from "../../../comp/ContextMenuButton"
import { LabelWith } from "../../../comp/Label"
import { DependsDataContext } from "../../../model/depends"
import { GalleryLayoutKind, ISlideGalleryField } from "../../../model/pptx"
import strings from "../../../strings"

interface EditGalleryTabProps {
    data: ISlideGalleryField
    isTemplate: boolean
    context?: DependsDataContext
    onChange: (nextData: ISlideGalleryField) => void
    onBreakImages?: () => void
    onClose: () => void
}

export function EditGalleryTab({ data, isTemplate, context, onChange, onBreakImages, onClose }: EditGalleryTabProps) {
    const items = data.items

    function layoutKindHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        const nextKind = Object.values(GalleryLayoutKind).find(kind => kind === e.target.value)
        if (!nextKind)
            return
        onChange({...data, kind: nextKind})
    }
    function setText(value: string) {
        onChange({...data, items: value})
    }

    function breakImagesHandler() {

        onClose()
    }

    return (
        <div className="flex flex-col gap-2">
            <LabelWith text={strings.lab_photos}>
                <div className="flex flex-row gap-2 items-center">
                    <textarea className="textfield w-full flex-auto" 
                        value={items}
                        onChange={e => setText(e.target.value)}>
                    </textarea>
                    { context &&
                    <ContextMenuButton icon="photo_library" 
                        menu={context.toMenu(context.gallery)}
                        onClick={mi => setText(mi.id)}
                        />
                    }
                </div>
            </LabelWith>
            <LabelWith text="Макет">
                <select className="textfield"
                    value={data.kind} 
                    onChange={layoutKindHandler}>
                    {Object.values(GalleryLayoutKind).map( kind => 
                    <option value={kind} key={kind}>{kind}</option>
                    )}
                </select>
            </LabelWith>
            <LabelWith text="Gap">
                <input type="number" className="textfield"
                    value={data.gap || ""} 
                    onChange={e => onChange({...data, gap: parseInt(e.target.value) || 0})}/>
            </LabelWith>
            {!isTemplate && onBreakImages &&
            <button className="redbutton" onClick={onBreakImages}>Разбить на картинки</button>
            }
        </div>
    )
}