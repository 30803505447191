import { IContextMenuItem } from "./comp/ContextMenu"
import strings from "./strings"

const DELETE = "delete"
const REMOVE = "remove"
const EDIT = "edit"

const COPY_URL = "copy_url"
const SEND_MESSAGE = "send_message"
const REMOVE_ACCESS = "remove_access"
const SET_PASSWORD = "set_password"

const PROCESS = "process"

const menus = {
    DELETE, REMOVE, EDIT, 
    SEND_MESSAGE, REMOVE_ACCESS, SET_PASSWORD,
    COPY_URL, PROCESS,

    edit: { id: EDIT, title: strings.mi_edit } as IContextMenuItem,
    delete: { id: DELETE, title: strings.mi_delete } as IContextMenuItem,
    remove: { id: REMOVE, title: strings.mi_remove } as IContextMenuItem,
    copyUrl: { id: COPY_URL, title: strings.mi_copy_url } as IContextMenuItem,
    sendMessage: { id: SEND_MESSAGE, title: strings.mi_send_message } as IContextMenuItem,
    removeAccess: { id: REMOVE_ACCESS, title: strings.mi_remove_access } as IContextMenuItem,
    setPassword: { id: SET_PASSWORD, title: strings.mi_edit } as IContextMenuItem,
}

export default menus