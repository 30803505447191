import { Label } from "./Label"

export interface ComboboxOption {
    key?: string
    value?: string | null
    text: string
}

function getKey<T>(item: T, items: T[], toKey: ((item: T) => string) | undefined) {
    if (toKey)
        return toKey(item)
    return items.indexOf(item) + ""
}

function getText<T>(item: T, toText?: ((item: T) => string) | undefined) {
    if (toText)
        return toText(item)
    return item + ""
}

interface ComboboxProps<T> {
    value: T
    setValue?: React.Dispatch<React.SetStateAction<T>>
    label?: string
    disabled?: boolean
    onChange?: (item: T) => void
    toKey?: (item: T) => string
    toText?: (item: T) => string
    items: T[]
}

export function Combobox<T>({ value, label, disabled, items, setValue, onChange, toKey, toText }: ComboboxProps<T>) {
    function changeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        // console.log(e.target.value)
        let next = items.find(item => getKey(item, items, toKey) === e.target.value)
        if (next) {
            setValue && setValue(next)
            onChange && onChange(next)
        }
    }
    return (
        <div className="flex flex-col">
            {label &&
                <Label text={label} disabled={disabled} />
            }
            <select className="combobox"
                disabled={disabled}
                value={getKey(value, items, toKey)}
                onChange={changeHandler}
            >
                {items.map(item =>
                    <option
                        key={getKey(item, items, toKey)}
                        value={getKey(item, items, toKey)}>{getText(item, toText)}</option>
                )}
            </select>
        </div>
    )
}

export type ComboKeyText<T> = [key: T, text: string]

interface Combobox2Props<T> {
    value: T
    setValue: React.Dispatch<React.SetStateAction<T>>
    label?: string
    disabled?: boolean
    onChange?: (item: T) => void
    onBlur?: () => void
    items: ComboKeyText<T>[]
}

export function Combobox2<T>({ value, label, disabled, items, setValue, onChange }: Combobox2Props<T>) {
    function changeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        // console.log(e.target.value)
        let next = items.find(item => item[0] === e.target.value)
        if (next) {
            let key = next[0]
            setValue(key)
            onChange && onChange(key)
        }
    }
    return (
        <div className="flex flex-col">
            {label &&
                <Label text={label} disabled={disabled} />
            }
            <select className="combobox"
                disabled={disabled}
                value={value + ""}
                onChange={changeHandler}
            >
                {items.map(item =>
                    <option
                        key={item[0] + ""}
                        value={item[0] + ""}>{item[1]}</option>
                )}
            </select>
        </div>
    )
}

interface Combobox3Props<T> {
    value: T | undefined
    setValue: React.Dispatch<React.SetStateAction<T | undefined>>
    label?: string
    disabled?: boolean
    onChange?: (item: T | undefined) => void
    onBlur?: () => void
    items: ComboKeyText<T>[]
}

export function Combobox3<T>({ value, label, disabled, items, setValue, onChange }: Combobox3Props<T>) {
    function changeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        // console.log(e.target.value)
        let next = items.find(item => item[0] === e.target.value)
        if (next) {
            let key = next[0]
            setValue(key)
            onChange && onChange(key)
        }
    }
    return (
        <div className="flex flex-col">
            {label &&
                <Label text={label} disabled={disabled} />
            }
            <select className="combobox"
                disabled={disabled}
                value={value + ""}
                onChange={changeHandler}
            >
                <option
                    key={""}
                    value={undefined}></option>
                {items.map(item =>
                    <option
                        key={item[0] + ""}
                        value={item[0] + ""}>{item[1]}</option>
                )}
            </select>
        </div>
    )
}