import { CSSProperties, useState } from "react"
import { ContextMenu2, ContextMenuDir, IContextMenu } from "../../../comp/ContextMenu"
import { MAX_SPAN } from "../../../comp/docs/models"
import { IDocCell } from "../../../model/xlsx"
import { borderCss } from "./SlideBox"
import { SlideTextBox } from "./TextBox"
import { imageBoxStyle } from "./ImageBox"
import { IPoint, verticalAlignCss } from "../../../model/common"
import { PhotoSize } from "../../../model/models"
import { IconButton } from "../../../comp/Icon"
import strings from "../../../strings"

interface DocCellViewProps {
    cell: IDocCell
    firstRow?: boolean
    firstCol?: boolean
    lastCol?: boolean
    maxColumn: number
    menu?: IContextMenu<IDocCell>
    onDblClick?: (cell: IDocCell) => void
}

export function DocCellView({ cell, menu, firstRow, firstCol, lastCol, maxColumn, onDblClick } : DocCellViewProps) {
    // const [ showMenu, setShowMenu ] = useState(false)
    const [ showMenuPt, setShowMenuPt ] = useState<IPoint>()
    var style: CSSProperties = {
        border: borderCss(cell.border),
        paddingLeft: cell.padding?.left,
        paddingTop: cell.padding?.top,
        paddingRight: cell.padding?.right,
        paddingBottom: cell.padding?.bottom,
        background: cell.text?.style?.background,
        verticalAlign: verticalAlignCss(cell?.text?.verticalAlign)
    }
    const isImgTemplate = cell.image?.src?.startsWith("{") === true
    if (!isImgTemplate && cell.image) {
        style = {...style, ...imageBoxStyle(cell.image, PhotoSize.small)}
    }

    let span = cell?.span
    if (span === MAX_SPAN) {
        span = maxColumn - cell.col
    }

    function menuClickHandler(e: React.MouseEvent<HTMLButtonElement>) {
        // setShowMenu(!showMenu)
        let pt = { x: e.pageX - document.documentElement.scrollLeft, y: e.pageY - document.documentElement.scrollTop }
        setShowMenuPt(pt)
    }

    return (
        <td className={`relative select-none overflow-hidden0  min-w-[40px] excel-td ${firstRow ? "excel-first-row" : "excel-other-row"} ${firstCol ? "excel-first-col" : "excel-other-col"} ${lastCol ? "excel-last-col" : ""}`}
            colSpan={span}
            style={style}
            onDoubleClick={() => onDblClick && onDblClick(cell)}>
            <div className="overflow-hidden grid relative " >
                {cell.templ &&
                <span className="text-left text-xs font-mono font-bold bg-gray px-2">{cell.templ.expr}.map(item =&gt; </span>
                }
                {cell.text
                ? <SlideTextBox data={cell.text} isTemplate={false}/>
                : (cell.image && isImgTemplate)
                ? <>
                <span className="text-center bg-gray font-mono">{strings.lab_edit_field_image}</span>
                <span className="text-center bg-gray">{cell.image.src}</span>
                </>
                : <span></span>
                }
                {cell.templ &&
                <span className="text-left text-xs font-mono font-bold bg-gray px-2">)</span>
                }
            </div>
            {cell && menu && //onChangeSpan && 
            <IconButton className="flex-none0 absolute right-0 bottom-[2px] " name="more_vert"
                transparent color="black"
                size="16px" iconSize="14px"
                onClick={menuClickHandler}
                />
            }
            { menu && showMenuPt && 
            <ContextMenu2 menu={menu} pt={showMenuPt}
                item={cell}
                dir={ContextMenuDir.FromRightBottom}
                onClose={() => setShowMenuPt(undefined)}
                />
            }
        </td>
    )
}