import { useState } from "react"
import { IContextMenu } from "../../comp/ContextMenu"
import { IconButton } from "../../comp/Icon"
import { times } from "../../comp/docs/models"
import { FixedColCell } from "../../comp/docs/xlsx/FixedColCell"
import { IDocCell, IDocRow, IXlsxColumnWidths } from "../../model/xlsx"
import { DocRowView } from "./boxes/DocRowView"

export const DEF_COLUMN_WIDTH = 80
export const DEF_ROW_HEIGHT = 24

interface DocTableProps {
    rows: IDocRow[]
    maxColumn: number
    columnWidths?: IXlsxColumnWidths
    colMenu?: IContextMenu<number>
    rowMenu?: IContextMenu<IDocRow>
    cellMenu?: IContextMenu<IDocCell>
    onColResize: (col: number, width: number) => void
    onRowResize?: (row: IDocRow, height: number) => void
    onCellDblClick?: (cell: IDocCell) => void
}

export function DocTable({
    rows, maxColumn, columnWidths, colMenu, rowMenu, cellMenu, onColResize, onRowResize, onCellDblClick
}: DocTableProps) {
    return (
        <div className="overflow-x-scroll w-screen bg-white text-black pb-4"
            style={{ width: "98vw" }}>

            <table className="excel-grid0" style={{
                tableLayout: "fixed",
                width: "fit-content"
            }}>
                <thead>
                    <tr>
                        <FixedColCell />
                        {times(Math.max(maxColumn, 50), n =>
                            <FixedColCell key={n}
                                column={n}
                                width={(columnWidths && columnWidths[n]) || DEF_COLUMN_WIDTH}
                                onResize={onColResize}
                                menu={colMenu}
                            />
                        )}

                    </tr>
                </thead>

                <tbody>
                    {rows.map((row, index) =>
                        <DocRowView key={row.key}
                            row={row} firstRow={index === 0}
                            maxColumn={maxColumn}
                            rowMenu={rowMenu}
                            cellMenu={cellMenu}
                            onResize={onRowResize}
                            onCellDblClick={onCellDblClick}
                        />
                    )}
                </tbody>
            </table>
        </div>
    )
}

const DEF_PAGE_SIZE = 20

const PAGE_SIZES = [10, DEF_PAGE_SIZE, 30, 40, 50, 60, 70, 80, 90, 100]

export function DocTablePages({
    rows, maxColumn, columnWidths,
    colMenu, rowMenu, cellMenu,
    onColResize, onRowResize, onCellDblClick
}: DocTableProps) {
    const [page, setPage] = useState<number>(0)
    const [pageSize, setPageSize] = useState(DEF_PAGE_SIZE)
    const useRows = rows.slice(page * pageSize, page * pageSize + pageSize)
    return (
        <>
            <DocTable
                rows={useRows}
                maxColumn={maxColumn}
                columnWidths={columnWidths}
                colMenu={colMenu} rowMenu={rowMenu} cellMenu={cellMenu}
                onColResize={onColResize} onRowResize={onRowResize}
                onCellDblClick={onCellDblClick}
            />
            <div className="flex flex-row justify-center gap-2 px-4 py-2 items-center">
                <span className="mx-6 font-thin0">
                    {page + 1} / {Math.trunc(rows.length / pageSize) + 1}
                </span>

                <IconButton name="first_page" onClick={() => setPage(0)}
                    disabled={page === 0} />
                <IconButton name="arrow_left" onClick={() => setPage(page - 1)}
                    disabled={page === 0} />
                <IconButton name="arrow_right" onClick={() => setPage(page + 1)}
                    disabled={((page + 1) * pageSize) >= rows.length} />
                <IconButton name="last_page" onClick={() => setPage(Math.trunc(rows.length / pageSize))}
                    disabled={((page + 1) * pageSize) >= rows.length} />

                <select className="textfield mx-6"
                    value={pageSize}
                    onChange={e => setPageSize(parseInt(e.target.value) || pageSize)}
                >
                    {PAGE_SIZES.map(size =>
                        <option key={size} value={size}>{size}</option>
                    )}
                </select>
            </div>
        </>
    )
}