import api from "../api";
import { IContest, IContestEntity, EntityState, INomination, IPhoto, dbid, IContestUser, IStage, IContestExample, IAttachmentFile } from "../model/models";

function addToken(s: string, token: string | undefined | null, div: string) {
    if (token && s)
        return s + div + token
    else if (token)
        return token
    else
        return s
}

export default class DataService {

    static async uploadImage(file: File, contestId: dbid, entityId?: dbid) {
        let formData = new FormData();
        formData.append('file', file);
        var query = ""
        if (contestId)
            query = addToken(query, `contestId=${contestId}`, "&")
        if (entityId)
            query = addToken(query, `entityId=${entityId}`, "&")
        // console.log("Upload image ", query, contestId, entityId)
        return await api.post<IPhoto>(`/upload?${query}`, formData)
    }

    static async uploadDoc(file: File, contestId: dbid, entityId?: dbid) {
        let formData = new FormData();
        formData.append('file', file);
        var query = ""
        if (contestId)
            query = addToken(query, `contestId=${contestId}`, "&")
        if (entityId)
            query = addToken(query, `entityId=${entityId}`, "&")
        // console.log("Upload doc file ", query, contestId, entityId)
        return await api.post<IAttachmentFile>(`/attach?${query}`, formData)
    }

    static async deleteDoc(filename: string, contestId: dbid, entityId?: dbid) {
        var query = `filename=${filename}`
        if (contestId)
            query = addToken(query, `contestId=${contestId}`, "&")
        if (entityId)
            query = addToken(query, `entityId=${entityId}`, "&")
        // console.log("Delete doc file ", query, contestId, entityId)
        return await api.delete(`/attach?${query}`)
    }

    static async getContest(contestId: dbid) {
        return await api.get<IContest>(`/contests/${contestId}`)
    }

    static async saveContest(contestId: dbid | undefined, data: IContest) {
        const path = contestId
            ? `/contests/${contestId}` // Update contest 
            : "/contests"               // Create contest
        // return wrapResult(await api.post<IContest>(path, data))
        return await api.post<IContest>(path, data)// | ErrResponse
    }

    static async deleteContest(contestId: dbid) {
        return await api.delete(`contests/${contestId}`)
    }

    static async createContestFromExample(example: IContestExample, entityState: EntityState) {
        return await api.post<IContest>(`/examples`, { id: example.id, entityState })
    }

    static async deleteEmail(contestId: dbid) {
        return await api.delete(`/contests/${contestId}/email`)
    }

    static async setEmail(contestId: dbid, email: string) {
        return await api.post(`/contests/${contestId}/email/${email}`, null)
    }

    static async owningContest(contestId: dbid, email: string, remove: boolean) {
        const data = { email, remove }
        return await api.post<IContestUser>(`/contests/${contestId}/owners`, data)
    }

    static async inviteParticipateContest(contestId: dbid, email: string, remove: boolean) {
        const data = { email, remove }
        return await api.post<IContestUser>(`/contests/${contestId}/participants`, data)
    }

    // static async participateContest(contestId: dbid, remove: boolean) {
    //     const data = { remove }
    //     return await api.post<IContest>(`/contests/${contestId}/participants`, data)
    // }

    static async createStage(contestId: dbid, stage: IStage) {
        // const data = { title, description }
        return await api.post<IStage>(`/contests/${contestId}/stages`, stage)
    }

    static async updateStages(contestId: dbid, stages: IStage[]) {
        const data = { stages }
        return await api.post<IContest>(`/contests/${contestId}`, data)
    }

    static async getContestEntity(contestId: dbid, entityId: dbid) {
        return await api.get<IContestEntity>(`/contestants/${contestId}/${entityId}`)
    }

    static async saveContestEntity(contestId: dbid, data: IContestEntity) {
        const path = data?.id
            ? `/contestants/${contestId}/${data?.id}` // Update object
            : `/contestants/${contestId}`             // Create object

        return await api.post<IContestEntity>(path, data)
    }

    static async summingUpVoting(contestId: dbid, stageId: string, nominationId: dbid, entityIds: dbid[]) {
        let data = {
            stageId, nominationId, entityIds
        }
        return await api.post(`/contestants/${contestId}/summingup`, data)
    }

    static async updateContestEntity(contestId: dbid, entityId: dbid, data: any) {
        return await api.post<IContestEntity>(`/contestants/${contestId}/${entityId}`, data)
    }

    static async deleteContestEntity(contestId: dbid, entityId: dbid) {
        return await api.delete(`contestants/${contestId}/${entityId}`)
    }

    static async movePhotoToAttachmentContestEntity(contestId: dbid, entityId: dbid, field: string, photo: string, filename: string) {
        let data = { field, filename, photo } as IMovePhotoToAttachmentFileParams
        return await api.post<IContestEntity>(`contestants/${contestId}/${entityId}/movephototoattachment`, data)
    }

    static async submitContestEntity(contestId: dbid, entityId: dbid) {
        return await api.post(`contestants/${contestId}/${entityId}/submit`)
    }

    static async submitResultContestEntity(contestId: dbid, entityId: dbid, state: EntityState, message: string) {
        return await api.post(`contestants/${contestId}/${entityId}/result`, { state, message })
    }

    static async processEntityAttachment(contestId: dbid, entityId: dbid, filename: string) {
        return await api.post(`contestants/${contestId}/${entityId}/process?filename=${filename}`)
    }

    static async sendEntity(contestId: dbid, entityId: dbid, email: string) {
        return await api.post(`contestants/${contestId}/${entityId}/sendto?email=${email}`)
    }

    static async updateContesatantNominations(contestId: dbid, contestEntityId: dbid, nominationIds: dbid[]) {
        const data = {
            contestEntityId, nominationIds
        } as IUpdateNomintaionsParams

        return await api.post<IContestEntity>(`/participations/${contestId}/contestant/${contestEntityId}`, data)
    }

    static async getContestEntityNominations(contestId: dbid, entityId: dbid) {
        return api.get<INomination[]>(`/participations/${contestId}/contestant/${entityId}`)
    }

    static async saveNomination(contestId: dbid, nominationId: dbid | undefined, data: INomination) {
        const path = nominationId
            ? `/nominations/${contestId}/${nominationId}`
            : `/nominations/${contestId}`
        return await api.post<INomination>(path, data)
    }

    static async createNominationList(contestId: dbid, data: INomination[]) {
        return await api.post<INomination[]>(`/nominations/${contestId}/list`, data)
    }

    static async reorderNominations(contestId: dbid, data: IReorderParams) {
        return await api.post<INomination[]>(`/nominations/${contestId}/reorder`, data)
    }

    static async deleteNomination(contestId: dbid, nominationId: dbid) {
        return await api.delete(`/nominations/${contestId}/${nominationId}`)
    }
}

interface IUpdateNomintaionsParams {
    contestEntityId: dbid,
    nominationIds: dbid[]
}

export interface IReorderParams {
    [index: dbid]: number | undefined
}

interface IMovePhotoToAttachmentFileParams {
    field: string
    photo: string
    filename: string
}