import api from "../api"
import { dbid } from "../model/models"
import { AuthResponse } from "../model/response"

export class SubmitterService {

    static async start(contestId: String, email: String) {
        return api.post("/submitter/start", { contestId, email })
    }

    static async login(contestId: dbid, username: string, password: string) {
        return api.post<AuthResponse>("/submitter/login", { contestId, username, password })
    }

    static async check() {
        return api.get<AuthResponse>("/submitter")
    }
}