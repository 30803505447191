import { PhotoSize } from "../model/models"
import { ImageEx } from "./ImageFB"

function sizeToPx(size: PhotoSize): string {
    switch (size) {
        case PhotoSize.orig:
        case PhotoSize.large:
            return "300px"
        case PhotoSize.big:
            return "160px"
        case PhotoSize.medium:
            return "80px"
        case PhotoSize.small:
            return "40px"
    }
}

interface AvatarViewProps {
    src: string | null | undefined
    square?: boolean 
    className?: string
    size: PhotoSize
    sizePx?: string
    onClick?: () => void
}

export function AvatarView(props: AvatarViewProps) {
    var size = props.sizePx || sizeToPx(props.size)
    const style = {width: size, height: size}
    return (
        <div className={"object-cover bg-secondary " + (props.square ? "" : "rounded-full ") + (props.className || "")} style={style} onClick={props.onClick}>
        { props.src && 
            <ImageEx src={props.src} size={props.size}
                className={"object-cover " + (props.square ? "" : "rounded-full ")}
                style={style}
                />
        }
        </div>
    );
}

export function ImageView(props: AvatarViewProps) {
    var size = sizeToPx(props.size)
    const style = {width: size, height: size}
    return (
        <div className={"object-cover bg-secondary rounded-md " + (props.className || "")} style={style} onClick={props.onClick}>
        { props.src && 
            <ImageEx src={props.src} size={props.size}
                className="object-cover rounded-md" 
                style={style}
                />
        }
        </div>
    );
}