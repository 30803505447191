import { useDropzone } from "react-dropzone"

interface IFileUploaderProps {
    className?: string
    sendFile?: (files: File[]) => void
    children: React.ReactNode
}

export function FileUploader({ className, sendFile, children }: IFileUploaderProps) {
    const onDrop = async (acceptedFiles: File[]) => {
        sendFile && sendFile(acceptedFiles)
    }
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, // accept: { "image/png": [], "image/jpeg": [] },
    })
    const canEdit = sendFile != undefined

    return (
        <div className={`${className || ""} ${isDragActive ? "bg-success" : ""}`}
            {...(canEdit ? getRootProps() : {})}>
            {canEdit &&
                <input {...getInputProps()} />
            }
            {children}
        </div>
    );
}