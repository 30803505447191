import { ImageEx } from "../../comp/ImageFB"
import { useImageFullscreen } from "../../comp/forms/ImageFullscreen"
import { IContestEntity, PhotoSize } from "../../model/models"

function buildPhotos(mainPhoto: string, other: Array<string>) {
    if (mainPhoto) {
        return mainPhoto
    } else {
        const res = other[0] 
        other.splice(0,1)
        return res || ""
    }
}

export function PresentationSlide({ item } : { item: IContestEntity }) {
    let other = item.fields.photos?.split("\n") || []
    const main = buildPhotos(item.fields.main_photo || "", other)// || item.fields.photos[0] || ""
    
    const imageFullscreen = useImageFullscreen()
    return (
        <div className="flex flex-col justify-between items-center pt-8">
            <div className="text-xl pb-2">{item.title}</div>
            <div className="w-full flex flex-row gap-2 rounded overflow-hidden">
                {main &&
                <ImageEx key={item.id || item.title} 
                    src={main} 
                    size={PhotoSize.large}
                    className="w-2/3"
                    onClick={() => imageFullscreen.open([main, other])}/>
                }
                <div className="flex flex-col flex-auto gap-2">
                    {other.map( img => 
                    <ImageEx key={img} 
                        src={img} 
                        size={PhotoSize.large} 
                        onClick={() => imageFullscreen.open([img, other])}/>
                    )}
                </div>
            </div>
            {imageFullscreen.render()}
        </div>
    )
}