import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BackLink } from "../comp/BackLink";
import { ErrorMessage } from "../comp/ErrorMessage";
import { ImageEx } from "../comp/ImageFB";
import { LabelWith } from "../comp/Label";
import { ListItems, ListUseItems } from "../comp/ListItems";
import { Loading } from "../comp/Loading";
import { LogoView } from "../comp/LogoView";
import { FieldValue } from "../comp/fields/FieldValue";
import NominationRow from "../comp/rows/NominationRow";
import { useContest, useNominationsFor } from "../hooks/useItems";
import { useTitle } from "../hooks/useTitle";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { IContest, IContestEntity, PhotoSize, TemplateFieldType } from "../model/models";
import { GalleryLayoutKind, ISlideGalleryField } from "../model/pptx";
import paths from "../paths";
import DataService from "../services/DataService";
import strings from "../strings";
import { AttachmentFileRow } from "./ContestEntityPage";
import { SlideGalleryBox } from "./EditPresentationPage/boxes/GalleryBox";

export function EntityForJuryPage() {
    const { jury } = useTypedSelector(state => state.auth)
    const contestId = jury?.juryContestId || ""
    const entityId = useParams()["id"] || "0"

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const { contest, loading: contestLoading, error: contestError } = useContest(contestId)
    const [contestEntity, setContestEntity] = useState<IContestEntity | null>(null)
    const nominations = useNominationsFor(contestId, entityId)

    useTitle(strings.lab_contest_entity + (contestEntity ? " - " + contestEntity.title : ""))

    async function fetchData() {
        if (!contestId || !entityId)
            return
        setLoading(true)
        try {
            const resp = await DataService.getContestEntity(contestId, entityId)
            setContestEntity(resp.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [contestId, entityId])

    const imagesField = contest?.template?.fields?.find(fld => fld.type === TemplateFieldType.IMAGES)
    const imageField = contest?.template?.fields?.find(fld => fld.type === TemplateFieldType.IMAGE)

    let gallery: ISlideGalleryField | undefined
    let mainImage: string | undefined
    if (imagesField && contestEntity) {
        let galleryImages = contestEntity.fields[imagesField.name]
        if (galleryImages) {
            gallery = {
                items: galleryImages, kind: GalleryLayoutKind.AUTO,
                gap: 8
            }
        }
    }
    if (imageField && contestEntity) {
        mainImage = contestEntity.fields[imageField.name]
    }

    return (
        <div className="maincontainer w-full flex flex-col pb-big">
            <BackLink to={paths.contest_for_jury}
                text={strings.nav_contest} />

            {loading
                ? <Loading />
                : (contest && contestEntity)
                    ? <>
                        <h1 className="font-extralight text-3xl text-center pb-big">{contest.title}</h1>

                        <EntityHeaderView contest={contest} entity={contestEntity} />

                        {contest.template?.fields?.map(fld =>
                            <FieldValue key={fld.name}
                                contestId={contestId} entityId={contestEntity.id}
                                values={contestEntity.fields} field={fld}
                            />
                        )}

                        <LabelWith text={strings.lab_nominated}>
                            <ListUseItems useItems={nominations}
                                nobottom
                                render={item =>
                                    <NominationRow key={item.id} item={item} />
                                }
                            />
                        </LabelWith>

                        <LabelWith text={strings.lab_attachments}
                        >
                            <ListItems items={contestEntity.attachments || []}
                                render={m =>
                                    <AttachmentFileRow key={m.filename} file={m} />
                                } />
                        </LabelWith>

                        {/** contestEntity.texts && 
                <TextField label={strings.lab_texts_extracted}
                    multiline 
                    text={contestEntity.texts.join("\n")}
                    />
                 */}
                        <div className="flex flex-row justify-center pt-big">
                            <LogoView disabled size={40} />
                        </div>
                    </>
                    : (error || contestError)
                        ? <ErrorMessage message={error || contestError || ""} />
                        : <Loading />
            }
        </div>
    )
}

interface EntityHeaderViewProps {
    contest: IContest
    entity: IContestEntity
}

export function EntityHeaderView({ contest, entity }: EntityHeaderViewProps) {
    // useWindowSize()
    const imagesField = contest?.template?.fields?.find(fld => fld.type === TemplateFieldType.IMAGES)
    const imageField = contest?.template?.fields?.find(fld => fld.type === TemplateFieldType.IMAGE)

    let gallery: ISlideGalleryField | undefined
    let mainImage: string | undefined
    if (imagesField) {
        let galleryImages = entity.fields[imagesField.name]
        if (galleryImages) {
            gallery = {
                items: galleryImages, kind: GalleryLayoutKind.AUTO,
                gap: 8
            }
        }
    }
    if (imageField) {
        mainImage = entity.fields[imageField.name]
    }
    if (gallery || mainImage) {
        // console.log("EntityHeaderView", gallery, mainImage)
        // 730 / 410
        // console.log("window.innerWidth", global.window.innerWidth)
        return (
            <div className="relative rounded overflow-hidden drop-shadow mb-10">
                {gallery
                    ? <div className="relative h-[300px] ">
                        <SlideGalleryBox data={gallery}
                            field={{ key: "", pos: { x: 0, y: 0 }, size: { x: 730, y: 350 } }} />
                    </div>
                    : mainImage &&
                    <ImageEx className="w-full h-[300px]"
                        src={mainImage} size={PhotoSize.large} />
                }
                <h2 className="font-thin0 text-3xl text-right absolute bottom-0 w-full px-4 pt-10 pb-2 line-clamp-3 bg-gradient-to-t from-black/75 via-black/50 black/10">
                    {entity.title}
                </h2>
            </div>
        )
    } else {
        return (
            <LabelWith text={strings.lab_entity_name}>
                <h2 className="textfield font-thin0">
                    {entity.title}
                </h2>
            </LabelWith>
        )
    }
}