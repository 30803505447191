import { Link } from "react-router-dom";
import { DescrView } from "../comp/DescrView";
import { Label, LabelWith } from "../comp/Label";
import { ListItems } from "../comp/ListItems";
import { useTitle } from "../hooks/useTitle";
import { INewsHeader } from "../model/models";
import strings from "../strings";

const newsItems = [
    {
        title: 'Проведение конкурса "Новогодний Екатеринбург – 2025"',
        description: "C 02.12.2024 начинает прием заявок традиционный конкурс «Новогодний Екатеринбург». Заявки на участие принимаются со 2 по 23 декабря.",
        image: "/img/promoted/main.jpeg",
        uri: "/",
        // uri: "/news/winter-ekb-2025",
        date: "30.11.2025 22:37"
    } as INewsHeader
]

export default function NewsPage() {
    useTitle(strings.nav_news)
    return (
        <div className="flex flex-col maincontainer">
            <h1 className="header">{strings.nav_news}</h1>
            <LabelWith text={strings.nav_news} >
                <ListItems items={newsItems}
                    className="pt-2 pb-8"
                    render={item =>
                        <NewsItemView item={item} />
                    } />
            </LabelWith>
        </div>
    )
}

interface INewsItemViewProps {
    item: INewsHeader
}

function NewsItemView({ item }: INewsItemViewProps) {
    return (
        <Link to={item.uri} className="rounded-[20px] bg-primary2 flex flex-col p-4">

            <div className="flex flex-row flex-wrap gap-4">

                <img src={item.image}
                    className="rounded-lg flex-none w-full md:w-[300px] aspect-video object-cover" />

                <div className="flex flex-col">
                    <div className="text-xl font-bold">{item.title}</div>
                    <DescrView className="mt-4 text-sm line-clamp-6" body={item.description} />
                    <div className="mt-4 text-white/50">{item.date}</div>
                </div>

            </div>
        </Link>
    )
}