import { useMemo, useState } from "react"
import { LabelWith } from "../../comp/Label"
import { ListItems } from "../../comp/ListItems"
import { EntityState, IContest, INomination, TemplateFieldType } from "../../model/models"
import strings from "../../strings"
import { ContextMenu, IContextMenuItem } from "../../comp/ContextMenu"
import { DataEntitySortKind, DataNominationSortKind, IDataQuery, IQueryItem, Operations, TocData } from "../../model/query"

export function getEnum<T>(items: T[], value: string) {
    return getEnum0(items, value) || items[0]
}
export function getEnum0<T>(items: T[], value: string) {
    return items.find(item => item === value)// || items[0]
}
type KeyValue<T> = [item: T, text: string]

const NOMINATION_SORT_KINDS: KeyValue<DataNominationSortKind>[] = [
    [DataNominationSortKind.INDEX_ASC,  strings.sort_index_asc],
    [DataNominationSortKind.INDEX_DESC, strings.sort_index_desc],
    [DataNominationSortKind.TITLE_ASC,  strings.sort_title_asc],
    [DataNominationSortKind.TITLE_DESC, strings.sort_title_desc],
]
const ENTITIY_SORT_KINDS: KeyValue<DataEntitySortKind>[] = [
    [DataEntitySortKind.INDEX_ASC,  strings.sort_index_asc],
    [DataEntitySortKind.INDEX_DESC, strings.sort_index_desc],
    [DataEntitySortKind.TITLE_ASC,  strings.sort_title_asc],
    [DataEntitySortKind.TITLE_DESC, strings.sort_title_desc],

    [DataEntitySortKind.DATE_ASC,  strings.sort_create_asc],
    [DataEntitySortKind.DATE_DESC, strings.sort_create_desc],
    [DataEntitySortKind.VOTE_ASC,  strings.sort_vote_asc],
    [DataEntitySortKind.VOTE_DESC, strings.sort_vote_desc],
]

interface QueryFieldVariant {
    text: string
    value: string
}

interface QueryField {
    label: string
    name: string
    type?: "number" | "text"
    variants?: QueryFieldVariant[]
}

function buildQueryFields(contest: IContest, addVote?: boolean) {
    const res = Array<QueryField>()
    res.push({
        name: "state",
        label: strings.lab_entity_state,
        variants: [
            { value: EntityState.NOT_SUBMITTED, text: strings.entity_state_not_submitted },
            { value: EntityState.VERIFICATION, text: strings.entity_state_verification },
            { value: EntityState.APPROVED, text: strings.entity_state_approved },
            { value: EntityState.REJECTED, text: strings.entity_state_rejected },
            { value: EntityState.CORRECTION_REQUIRED, text: strings.entity_state_correction_required },
        ]
    })
    contest.template?.fields?.forEach( field => {
        if (field.type === TemplateFieldType.ENUM && field.enums) {
            res.push({ 
                name: "fields." + field.name,
                label: field.label, 
                variants: field.enums.map(e => ({
                    text: e, value: e
                })
                )})
        } else if (field.type === TemplateFieldType.BOOL) {
            res.push({ 
                name: "fields." + field.name,
                label: field.label, variants: [
                    { text: "Да", value: "true" },
                    { text: "Нет", value: "false" },
                ]
            })
        }
    })
    if (addVote) {
        res.push({
            name: "$votes",
            label: "Кол-во голосов",
            type: "number",
        })
    }
    return res
}

interface DataQueryViewProps {
    query: IDataQuery
    contest: IContest
    nominations: INomination[]
    addVote?: boolean
    className?: string
    onChange?: (nextQuery: IDataQuery) => void
}

export function DataQueryView({ query, contest, nominations, addVote, className, onChange }: DataQueryViewProps) {
    // const [ queryFields ] = useState()
    const queryFields = useMemo(() => buildQueryFields(contest, addVote), [contest, addVote])
    const [ nominationSort, setNominationSort ] = useState(query.nominationSort || DataNominationSortKind.INDEX_ASC)
    const [ entitySort, setEntitySort ] = useState(query.entitySort || DataEntitySortKind.INDEX_ASC)
    
    const [ menuAddNominations, setMenuAddNominations ] = useState<IContextMenuItem[]>()
    const [ menuEntityQueryFields, setMenuEntityQueryFields ] = useState<IContextMenuItem[]>()

    function moreNominationQueryHandler() {
        const menu = nominations.filter( item => query.nominationIds.indexOf(item.id) < 0)
            .map(nom => ({
                id: nom.id, title: nom.title
            } as IContextMenuItem))
        setMenuAddNominations(menu)
    }
    function addNominationQueryHandler(mi: IContextMenuItem) {
        onChange && onChange({...query, nominationIds: [...query.nominationIds, mi.id]})
    }
    function nominationClickHandler(nom: INomination) {
        if (onChange && global.confirm("remove this nomination from query?")) {
            onChange({...query, nominationIds: query.nominationIds.filter(id => id !== nom.id)})
        }
    }
    function moreEntityQueryField() {
        const menu = queryFields.map(field => ({
            id: field.name,
            title: field.label,
            items: field.variants?.map(v => 
                ({ id: field.name + "=" + v.value, title: v.text } as IContextMenuItem)
            )
        } as IContextMenuItem))

        setMenuEntityQueryFields(menu)
    }
    function addEntityQueryField(mi: IContextMenuItem) {
        if (!onChange)
            return
        const divIndex = mi.id.indexOf('=')
        if (divIndex < 0) {
            let field = queryFields.find( fld => fld.name === mi.id)
            if (field?.type) {
                let value = global.prompt("Введите значение")
                if (value === null)
                    return
                let item: IQueryItem
                if (field.type === "number") {
                    let operation: Operations = "="
                    if (value.startsWith(">=")) {
                        operation = ">="
                        value = value.substring(2)
                    } else if (value.startsWith("<=")) {
                        operation = "<="
                        value = value.substring(2)
                    } else if (value.startsWith("<")) {
                        operation = "<"
                        value = value.substring(1)
                    } else if (value.startsWith(">")) {
                        operation = ">"
                        value = value.substring(1)
                    } else if (value.startsWith("=")) {
                        operation = "="
                        value = value.substring(1)
                    } 
                    let numb = Number.parseInt(value)
                    if (Number.isNaN(numb))
                        return global.alert("Некорректное значение!")
                    item = { 
                        field: field.name, label: field.label, value: numb + "",
                        operation
                    } as IQueryItem
                } else {
                    item = { field: field.name, label: field.label, value: value } as IQueryItem
                }
                onChange({...query, entityQueries: [...query.entityQueries, item]})
            }
            return
        } else {
            const fieldName = mi.id.substring(0, divIndex)
            const field = queryFields.find( field => field.name === fieldName)
            if (!field) 
                return
            const item = { field: field.name, label: field.label, value: mi.id.substring(divIndex + 1) } as IQueryItem
            onChange({...query, entityQueries: [...query.entityQueries, item]})
        }
    }
    function entityQueryItemClickHandler(item: IQueryItem) {
        if (onChange && global.confirm("remove this item?")) {
            onChange({...query, entityQueries: query.entityQueries.filter( m => m !== item)})
        }
    }
    function nominationSortCahngeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let next = getEnum(Object.values(DataNominationSortKind), e.target.value)
        setNominationSort(next)
        onChange && onChange({...query, nominationSort: next })
    }
    function entitySortChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let next = getEnum(Object.values(DataEntitySortKind), e.target.value)
        setEntitySort(next)
        onChange && onChange({...query, entitySort: next })
    }
    function stageChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        onChange && onChange({...query, stageId: e.target.value })
    }

    return (
        <div className="flex flex-col px-4">
            <LabelWith text="Заявки отсеянные на стадии">
                <select className="textfield" value={query.stageId || ""} onChange={stageChangeHandler}>
                    <option value=""></option>
                    {contest.stages?.map( stg => 
                    <option key={stg.id} value={stg.id}>{stg.title}</option>
                    )}
                </select>
            </LabelWith>
            <div className={"mt-2 flex flex-col md:flex-row justify-around " + (className || "")}>
                <LabelWith text="Номинации">
                    <ListItems className="max-h-[200px] w-[300px] overflow-y-auto"
                        items={nominations.filter(sect => query.nominationIds.indexOf(sect.id) >= 0 )}
                        emptyText={`Все номинации (${nominations.length})`}
                        moreTitle="Добавить"
                        onMore={onChange && moreNominationQueryHandler}
                        render={sect =>
                            <span key={sect.id} className="itemrow"
                                onClick={() => nominationClickHandler(sect)}>{sect.title}</span>
                        }/>
                    <LabelWith text="Сортировка номинаций" className="">
                        <select className="textfield" value={nominationSort} 
                            disabled={!onChange}
                            onChange={nominationSortCahngeHandler}>
                            {NOMINATION_SORT_KINDS.map(knd => 
                            <option key={knd[0]} value={knd[0]}>{knd[1]}</option>
                            )}
                        </select>
                    </LabelWith>

                    {menuAddNominations &&
                    <ContextMenu menu={menuAddNominations} 
                        onClick={addNominationQueryHandler}
                        onClose={() => setMenuAddNominations(undefined)}/>
                    }
                </LabelWith>

                <span className="self-center text-disabled">AND</span>

                <LabelWith text="Условия выборки заявок" className="w-[300px]">
                    <ListItems className="max-h-[200px] w-[300px]"
                        items={query.entityQueries}
                        emptyText="Все заявки"
                        moreTitle="Добавить условие"
                        onMore={onChange && moreEntityQueryField}
                        render={item =>
                            <span key={item.field} className="itemrow"
                                onClick={() => entityQueryItemClickHandler(item)}>{`${item.label} ${item.operation || "="} ${item.value}`}</span>
                        }/>

                    <LabelWith text="Сортировка заявак" className="">
                        <select className="textfield" value={entitySort} 
                            disabled={!onChange}
                            onChange={entitySortChangeHandler}>
                            {ENTITIY_SORT_KINDS.map(knd => 
                            <option key={knd[0]} value={knd[0]}>{knd[1]}</option>
                            )}
                        </select>
                    </LabelWith>
                    {menuEntityQueryFields &&
                    <ContextMenu menu={menuEntityQueryFields} 
                        onClick={addEntityQueryField}
                        onClose={() => setMenuEntityQueryFields(undefined)}/>
                    }    
                </LabelWith>

            </div>
        </div>
    )
}

interface DataQueryTocViewProps {
    query: IDataQuery
    toc: TocData
    addVote?: boolean
    className?: string
    onChange?: (nextQuery: IDataQuery) => void
}

export function DataQueryTocView({ query, toc, addVote, className, onChange }: DataQueryTocViewProps) {
    const nominations = useMemo(() => toc.sections.map(sect => sect.nomination), [toc])
    return <DataQueryView query={query} className={className} onChange={onChange}
        contest={toc.contest} addVote={addVote}
        nominations={nominations}
        />
}