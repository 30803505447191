
export const RESIZE_BOX_SIZE = 9
// const WEIGHT = 2

//⌜⌝
//⌞⌟

export enum ResizeBoxKind {
    TopLeft, TopRight, BottomLeft, BottomRight,
    Left, Top, Right, Bottom,
    All
}

interface ResizeBoxProps {
    kind: ResizeBoxKind
    color?: string
    className?: string
}

export function ResizeBox({ kind, color, className }: ResizeBoxProps) {
    const classColor = color ? "bg-" + color : "bg-black"
    switch (kind) {
        case ResizeBoxKind.TopLeft:
            return (
                <div className={`absolute left-0 top-0 cursor-nwse-resize w-[8px] h-[8px] flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[2px] h-[8px]"}></div>
                    <div className={classColor + " w-[6px] h-[2px]"}></div>
                </div>
            )
        case ResizeBoxKind.TopRight:
            return (
                <div className={`absolute right-0 top-0 cursor-nesw-resize w-[8px] h-[8px] flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[6px] h-[2px]"}></div>
                    <div className={classColor + " w-[2px] h-[8px]"}></div>
                </div>
            )
        case ResizeBoxKind.BottomLeft:
            return (
                <div className={`absolute left-0 bottom-0 cursor-nesw-resize w-[8px] h-[8px] flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[2px] h-[8px]"}></div>
                    <div className={classColor + " w-[6px] h-[2px] self-end"}></div>
                </div>
            )
        case ResizeBoxKind.BottomRight:
            return (
                <div className={`absolute right-0 bottom-0 cursor-nwse-resize w-[8px] h-[8px] flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[6px] h-[2px] self-end"}></div>
                    <div className={classColor + " w-[2px] h-[8px]"}></div>
                </div>
            )
        case ResizeBoxKind.Left:
            return (
                <div className={`absolute left-0 absolute-y-center cursor-ew-resize flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[2px] h-[10px]"}></div>
                </div>
            )
        case ResizeBoxKind.Right:
            return (
                <div className={`absolute right-0 absolute-y-center cursor-ew-resize flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[2px] h-[10px]"}></div>
                </div>
            )
        case ResizeBoxKind.Top:
            return (
                <div className={`absolute absolute-x-center top-0 cursor-ns-resize flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[10px] h-[2px]"}></div>
                </div>
            )
        case ResizeBoxKind.Bottom:
            return (
                <div className={`absolute absolute-x-center bottom-0 cursor-ns-resize flex flex-row ` + (className || "")}>
                    <div className={classColor + " w-[10px] h-[2px]"}></div>
                </div>
            )
        case ResizeBoxKind.All:
            return (
                <>
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.TopLeft} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.TopRight} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.BottomLeft} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.BottomRight} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.Left} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.Right} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.Top} />
                <ResizeBox className={className} color={color} kind={ResizeBoxKind.Bottom} />
                </>
            )
    }
}