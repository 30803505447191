import { useParams, useSearchParams } from "react-router-dom"
import { useTypedSelector } from "../hooks/useTypedSelector"
import { ErrorMessage } from "../comp/ErrorMessage"
import strings from "../strings"
import { useState } from "react"
import { IVoteResult, IVoteSubmit } from "../model/models"
import { Loading } from "../comp/Loading"
import { DotsSliderView } from "../comp/DotsSliderView"
import { VoteSlide } from "./views/VoteSlide"
import { NominationSlide } from "./views/NominationSlide"
import { PresentationSlide } from "./views/PresentationSlide"
import { FinalSlide } from "./views/FinalSlide"
import { useTitle } from "../hooks/useTitle"
import VoteService from "../services/VoteService"
import { useTocData } from "../hooks/useTocData"
import { VotePlace, VotePlaceItems } from "../model/pptx"
import { TocSection } from "../model/query"

function voteSubmitToItem(submit: IVoteSubmit | undefined): VotePlaceItems<number> | undefined {
    if (!submit)
        return undefined
    let items: VotePlaceItems<number> = new Array()
    Object.keys(submit).forEach(itemId => {
        let place = submit[itemId]
        place && items.push({ itemId, place } as VotePlace<number>)
    })
    return items
}

export function readParam(searchParams: URLSearchParams, name: string): number {
    let param = searchParams.get(name)
    if (!param)
        return 0
    return parseInt(param) || 0
}

interface IPresentationIndex {
    section: number
    item: number
    final: boolean
}

export function PresentationPage() {
    const { jury } = useTypedSelector(state => state.auth)
    const contestId = useParams().id || jury?.juryContestId || ""
    const [searchParams, setSearchParams] = useSearchParams()

    const [error, setError] = useState<string>()
    const [index, setIndex] = useState<IPresentationIndex>({
        section: readParam(searchParams, "section"),
        item: readParam(searchParams, "item")
    } as IPresentationIndex)
    // const [ canLoadNext, setCanLoadNext ] = useState(true)
    const [voteResult, setVoteResult] = useState<IVoteResult>()
    const { toc, loading, loadSection } = useTocData(contestId, { onlySection: index.section })
    useTitle(strings.lab_presentation)

    function updateIndex(section: number | null, item: number | null) {
        if (!toc)
            return
        let nextIndex = {
            section: section !== null ? section : index.section,
            item: item !== null ? item : index.item,
            final: section === toc.sections.length
        }
        setIndex(nextIndex)
        setSearchParams({ ["section"]: nextIndex.section + "", ["item"]: nextIndex.item + "" })
    }

    function changeVoteHandler(section: TocSection, result: VotePlaceItems<number>) {
        // if (!voteResult) {
        //     console.log("Empty vote id")
        //     return
        // }
        // section.waitResult = result
    }
    async function submitVoteHandler(section: TocSection, result: VotePlaceItems<number>) {
        //     if (!voteResult) {
        //         console.log("Empty vote id")
        //         return
        //     }
        //     let sectionId = section.nomination.id || ""
        //     let submit = { } as IVoteSubmit
        //     result.forEach( m => {
        //         submit[m.itemId] = m.place
        //     })
        //     try {
        //         const resp = await VoteService.presentationVote(contestId, voteResult.id, sectionId, submit)
        //         if (resp.statusText === "OK")
        //             voteResult.items[sectionId] = submit
        //     } catch (error) {
        //         console.error(error)
        //     }
    }

    // useEffect(() => {
    //     fetchToc()
    // }, [contestId])

    if (!contestId) {
        return (
            <ErrorMessage message={strings.err_load_contest} />
        )
    }
    async function firstClickHandler() {
        if (!toc)
            return
        let section = toc.sections[0]
        if (!section || !toc)
            return
        if (!section.loaded) {
            // fetchSection(section, sections, false)
            await loadSection(section, toc)
            // setSections([...sections])
            // if (toEnd)
            //     updateIndex(sections.indexOf(section), section.items.length + 1)
        }
        updateIndex(0, 0)
    }
    async function prevClickHandler() {
        if (!toc)
            return
        if (index.item > 0) {
            updateIndex(null, index.item - 1)
        } else if (index.section > 0) {
            let prevSection = toc.sections[index.section - 1]
            if (!prevSection)
                return
            if (prevSection.loaded) {
                updateIndex(index.section - 1, prevSection.items.length + 1)
            } else {
                // fetchSection(prevSection, sections, true)
                await loadSection(prevSection, toc)
                // setSections([...sections])
                updateIndex(toc.sections.indexOf(prevSection), prevSection.items.length + 1)
            }
        }
    }
    async function nextClickHandler() {
        if (!toc)
            return
        let section = toc.sections[index.section]
        if (!section || !toc) {
            // console.log("Empty section ", index)
            return
        }
        // console.log(`Next click ${index.section},${index.item}(${section.items.length})`)
        // let isVoteSlide = index.item === section.items.length + 1
        // if (isVoteSlide && section.waitResult) {
        //     console.log("It was voting Slide")
        // It was voting Slide 
        // submitVoteHandler(section, section.waitResult)
        // section.waitResult = undefined
        // }

        if (index.item >= section.items.length + 1) {
            let nextSection = toc.sections[index.section + 1]
            if (nextSection && !nextSection.loaded) {
                // fetchSection(nextSection, sections, false)
                await loadSection(nextSection, toc)
                // setSections([...sections])
                // updateIndex(sections.indexOf(nextSection), nextSection.items.length + 1)
            }
            updateIndex(index.section + 1, 0)
        } else {
            updateIndex(null, index.item + 1)
        }
    }
    async function nextSectionClickHandler() {
        if (!toc)
            return
        let nextSection = toc.sections[index.section + 1]
        if (nextSection) {
            if (!nextSection.loaded) {
                // fetchSection(nextSection, sections, false)
                await loadSection(nextSection, toc)
                // setSections([...sections])
                // updateIndex(sections.indexOf(nextSection), nextSection.items.length + 1)
            }
            updateIndex(index.section + 1, 0)
        }
    }
    // function toVoteClickHandler() {
    //     if (!toc)
    //         return
    //     let section = toc.sections[index.section]
    //     if (!section) {
    //         console.log("Empty section ", index)
    //         return
    //     }
    //     updateIndex(null, section.size - 1)
    // }

    const currentSection = toc?.sections[index.section]

    const canPrev = index.section > 0 || index.item > 0
    const canNext = toc && currentSection && (index.section < toc.sections.length || index.item < currentSection.items.length + 2)
    // const canVote = currentSection && index.item != currentSection.size - 1
    const canNextSection = toc && index.section < toc.sections.length - 1

    return (
        <div className="flex flex-col ">
            <div className="debugfield maincontainer">
                ID: #{contestId},
                Toc loaded: {toc ? toc.sections.length + "" : "NO"},
                {/* Can load next: {canLoadNext ? "YES" : "NO"},  */}
                IndexSection: {index.section}({toc?.sections?.length}),
                IndexItems: {index.item}({currentSection?.items?.length})
            </div>
            {loading
                ? <Loading text={strings.msg_presentation_loading} />
                : toc
                    ? <>
                        <div className="flex flex-row gap-x-2 maincontainer">
                            <button className="textbutton" onClick={firstClickHandler} disabled={!canPrev}>
                                {strings.button_first}
                            </button>
                            <button className="textbutton" onClick={prevClickHandler} disabled={!canPrev}>
                                {strings.button_prev}
                            </button>
                            <button className="textbutton" onClick={nextClickHandler} disabled={!canNext}>
                                {strings.button_next}
                            </button>
                            <div className="flex-auto"></div>
                            {/* <button className="textbutton" onClick={toVoteClickHandler} disabled={!canVote}>
                        {strings.button_vote}
                    </button> */}
                            <button className="textbutton" onClick={nextSectionClickHandler} disabled={!canNextSection}>
                                {strings.button_next_nomination}
                            </button>
                        </div>

                        <DotsSliderView className="maincontainer mt-8"
                            index={index.section} count={toc.sections.length} />
                        <div className="slidewrapper">
                            <div className="my-8 rounded-lg overflow-hidden bg-primary2 w-[960px] h-[540px]">
                                <SlideSelector
                                    index={index}
                                    sections={toc.sections}
                                    setIndex={updateIndex}
                                    voteResult={voteResult}
                                    onChangeVote={changeVoteHandler}
                                    onSubmitVote={submitVoteHandler}
                                />
                            </div>
                        </div>
                    </>
                    : <ErrorMessage message={error || "Error while load"} />
            }
        </div>
    )
}

interface SlideSelectorProps {
    index: IPresentationIndex
    sections: TocSection[]
    voteResult?: IVoteResult
    setIndex: (section: number | null, item: number | null) => void
    onChangeVote?: (section: TocSection, result: VotePlaceItems<number>) => void
    onSubmitVote: (section: TocSection, result: VotePlaceItems<number>) => void
}

function SlideSelector({ index, sections, voteResult, setIndex, onChangeVote, onSubmitVote }: SlideSelectorProps) {
    if (index.final)
        return <FinalSlide />
    const currentSection = sections[index.section]
    if (!currentSection) {
        console.error(strings.err_presentation_nomination, sections)
        return <ErrorMessage message={strings.err_presentation_nomination} />
    }
    else if (index.item === 0)
        return <NominationSlide item={currentSection.nomination} />
    else if (!currentSection.loaded)
        return <Loading />
    else if (index.item === currentSection.items.length + 1)
        return <VoteSlide
            section={currentSection}
            initResult={voteSubmitToItem(voteResult?.items[currentSection.nomination.id!!])}
            onGotoSlide={(slideIndex) => setIndex(null, slideIndex + 1)}
            onChange={onChangeVote}
            onSubmit={onSubmitVote} />
    else {
        const currentItem = currentSection.items[index.item - 1]
        if (currentItem)
            return <PresentationSlide item={currentItem.entity} />
        else
            return <ErrorMessage message={strings.err_presentation_nominee} />
    }
}